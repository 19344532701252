import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import OrderContext from "../../OrderContext";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import styles from "./Payment.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputField from "../../components/InputField/InputField";
import iconCreditCard from "../../images/icon-credit-card.svg";
import iconDirectDebit from "../../images/icon-direct-debit.svg";
import {InfoIcon} from "../../atoms/Icons/Icons"
import DirectDebitForm from "../../components/DirectDebitForm/DirectDebitForm";
import CheckboxField from "../../components/CheckboxField/CheckboxField";
import BackNextButtons from "../../components/BackNextButtons/BackNextButtons";
import Button from "../../components/Button/Button";
import Error from "../../components/Error/Error";
import DataStore from "../../DataStore";
import {
    CODE_WHISTLEOUT, CREDIT_CARD, CREDIT_CARD_FEE_TEXT, DIRECT_DEBIT, DIRECT_DEBIT_OPTION, ENV_PRODUCTION,
    METHOD_CREDIT,
    METHOD_DEBIT, METHOD_NONCE,
    SOURCE_WHISTLEOUT,
    TECH_FIBRE,
    TYPE_BROADBAND,
    TYPE_MOBILE,
    COUNTRY_CODE_AUSTRALIA,
    AUTHORISED_TRANSFER_OPTIONS, AGENT_CODE_EXE, METHOD_PCIPAL
} from "../../constants";
import LoadingOverlay from "../../layout/LoadingOverlay/LoadingOverlay";
import {handleError, noWidows, pushEvent, pushGa4Event, serviceAddressParse, getAddressByLocationId} from "../../globals";
import { useCookie } from "react-use";
import NoncePaymentForm from "../../components/NoncePaymentForm/NoncePaymentForm";
import DropIn from "../../components/Braintree/DropIn";
import DataSource from "../../DataSource";
import { GetToken } from "../../features/getToken";
import RadioButtons from "../../components/RadioButtonGroup/RadioButtons"
import InputLabel from "../../components/InputLabel/InputLabel"
import medicalDisclaimerData from "../../data/medicalDisclaimer.json"
import {paymentSubmitEvent, paymentInfoEvent} from "../../events"

const Payment = (props) => {

    const { order, orderType, isLoggedIn, updateOrder, updateOrderState } = useContext(OrderContext);
    const { register, handleSubmit, control, setValue, trigger, reset, clearErrors, formState: { errors } } = useForm();
    const history = useHistory();
    const [partnerizeCookie] = useCookie('partnerizeClickReference');
    const [isLoading, setIsLoading] = useState(false);
    const [method, setMethod] = useState(order.paymentMethod === '' ? null : order.paymentMethod);

    const [validationErrors, setValidationErrors] = useState(null);
    const [showPaymentFormForUser, setShowPaymentFormForUser] = useState(false);
    const [dropInInstance, setDropInInstance] = useState(null);
    const [clientToken, setClientToken] = useState('');

    const [agentPortalToken, setAgentPortalToken] = useState('');
    const [agentPortalCode, setAgentPortalCode] = useState('');
    const [isAgent, setIsAgent] = useState()
    const [isEndUserAuthorised, setIsEndUserAuthorised] = useState(false)

    useEffect(() => {
        const checkAgentStatus = () => {
            const isAgentFlag = localStorage.getItem('isAgent') === 'true'
            const agentToken = localStorage.getItem('orderFormToken') || null
            const agentCode = localStorage.getItem('agentCode') || null
            setIsAgent(isAgentFlag)
            setAgentPortalToken(agentToken)
            setAgentPortalCode(agentCode)
        }

        checkAgentStatus()
        const handleIsAgentChange = () => {
            checkAgentStatus()
        }
    
        window.addEventListener('isAgentChanged', handleIsAgentChange)
        return () => {
            window.removeEventListener('isAgentChanged', handleIsAgentChange)
        };
    }, [setValue])

    useEffect(() => {
        if (orderType === TYPE_MOBILE || order.mobileBundle) order.termsOfUse = false;
        pushEvent('paymentPageView');
    }, []);

    const checkAgentToEnableNonceOption = useCallback(() => {
            return isAgent && agentPortalCode === AGENT_CODE_EXE
        }, [agentPortalCode, isAgent])

    useEffect(() => {
        // Form needs to be reset after an API call in order to update the values
        reset(order);
        if (order.mobileBundle) setMethod(METHOD_CREDIT);
        else setMethod(order.paymentMethod === '' ? null : order.paymentMethod);
        setValue('referrerCode', order.source === SOURCE_WHISTLEOUT && !order.referrerCode ? CODE_WHISTLEOUT : order.referrerCode);
        // Reset the payment form values for logged in users
        if (isLoggedIn) {
            resetPaymentForm();
        }
        (async () => {
            const clientToken = await GetToken.getClientToken();
            if (clientToken) {
                setClientToken(clientToken)
            }
        })()
    }, [order]);

    const paymentType = method === METHOD_CREDIT ? CREDIT_CARD : DIRECT_DEBIT;
  

    const submitOrder  = (data) => {
        data.completed = true;
        data.page = 'Payment'
        let response;
        let result;
        setIsLoading(true);
        setValidationErrors(null);
        updateOrder(data).then(res => {
            updateOrderState({
                customerId: res.data.data.customerId,
                serviceId: res.data.data.serviceId,
                ...(order.conversationId && {conversationId: order.conversationId})
            });
            handleSuccess({
                ...data,
                customerId: res.data.data.customerId,
                ...(order.conversationId && {conversationId: order.conversationId})
            });
            response =  res.data.data.orderStatus
            result = res.data.status
        }).catch(error => {
            if (error.response.status === 422 || error.response.status === 403 || error.response.status === 401) {
                // Show validation error
                if (error.response.data && error.response.data) {
                    setValidationErrors(error.response.data.errors);
                } else {
                    setValidationErrors('Oops! It appears something went wrong, please try again.');
                }
            } else {
                //Some other error occurred, log and show error screen
                setValidationErrors('Oops! It appears something went wrong, please try again.');
                handleError('Error submitting payment in handleSubmitEvent.', error.response);
            }
            response = JSON.stringify(error.response.data.errors.form)
            result = error.response.status
        }).finally(() => {
            let voipAddonItem = data.items.find(item => item.item_category === 'voip addon');
            let eventSubmitEvent = {
                "description": response,
                "event": {
                  "agentCode": data.agentCode,
                  "orderLogCode": data.code,
                  "result": result,
                  "pageUrl": window.location.href,
                },
                "eventType": "USER"
            }
            data.type === 'broadband' && (eventSubmitEvent.event['broadband'] = {
                id: data.planId,
                name: data.planName,
                provider: data.provider
            });
            voipAddonItem && (eventSubmitEvent.event["voip"] = {id: voipAddonItem.item_id, name: voipAddonItem.item_name});
            data.type === 'mobile' && (eventSubmitEvent.event['mobile'] = {id: data.planId, name: data.planName});
            DataSource.logApiEvent(eventSubmitEvent)
            setIsLoading(false);
        });
    }

    const setDataThreeDSecure = async (data) => {
        let threeDSecureData = {}
        if (data && data.serviceAddress) {
            const {userPostcode, userSuburb, userState, userStreet} = await getAddressByLocationId(order.serviceLocationId)
            if (userPostcode && userSuburb && userState) {
                threeDSecureData = {
                    amount: data.monthlyTotal > 0 ? data.monthlyTotal : (data.monthlyTotalNoPromotion ? data.monthlyTotalNoPromotion: null),
                    email: data.email ? data.email: '',
                    billingAddress: {
                        givenName: data.firstName ? data.firstName: '',
                        surname: data.lastName ? data.lastName: '',
                        phoneNumber: data.contact ? data.contact: '',
                        streetAddress: userStreet && userStreet,
                        locality: userSuburb && userSuburb,
                        region: userState && userState,
                        postalCode: userPostcode && userPostcode,
                        countryCodeAlpha2: COUNTRY_CODE_AUSTRALIA
                    },
                }
            }
        }
        return threeDSecureData
    }

    const checkThreeDSecureAuthentication = (payload) => {
        if (payload.threeDSecureInfo) {
            if (payload.threeDSecureInfo?.liabilityShifted) {
                return payload.nonce
            } else {
                setValidationErrors('Sorry, we are unable to verify the credit card you have provided. Please try with a different card or contact us on 1800 57 87 37.')            }
        }
    }

    const handleSubmitEvent = async data => {
        if (isAgent) {
            data.order_form_token = agentPortalToken;
            data.agentCode = agentPortalCode;
        }

        if (order.activeService && order.activeService > 0) {
            if (!isEndUserAuthorised) return
            data.isEndUserAuthorised = isEndUserAuthorised
        }
        paymentSubmitEvent(data) // trigger the payment submit event G4A
        if (method === METHOD_CREDIT || method === CREDIT_CARD || method == null) {
            if(dropInInstance) {
                if (!isLoggedIn && (!isAgent && !data.agentCode)) {
                    dropInInstance.requestPaymentMethod({
                        threeDSecure: await setDataThreeDSecure(data)
                    },function (err, payload) {
                        if (err) {
                            return;
                        } else {
                            const nonce = checkThreeDSecureAuthentication(payload)
                            if (nonce) {
                                data.paymentMethod = METHOD_NONCE;
                                data.identifierType = 'nonce';
                                data.identifier = payload.nonce;
                                submitOrder(data)
                            }
                        }
                    })
                   } else {
                        dropInInstance.requestPaymentMethod(function (err, payload) {
                            if (err) return;
                            data.paymentMethod = METHOD_NONCE;
                            data.identifierType = 'nonce';
                            data.identifier = payload.nonce;
                            submitOrder(data)
                    })
                   }
            } else {
                if (isLoggedIn || (order.conversationId)) {
                    if (order.conversationId && order.conversationId.length > 0) {
                        data.paymentMethod = METHOD_PCIPAL;
                        data.identifierType = METHOD_PCIPAL;
                        data.genesysConversationId = order.conversationId;
                    }
                    submitOrder(data)
                } else {
                    setValidationErrors('Failed to load payment details form, please try again.');
                }   
            }
        } else {
            if (checkAgentToEnableNonceOption()) {
                data.identifier = data.nonce
            }
        
            submitOrder(data)
        }
    };

    const handleSuccess = data => {
        paymentInfoEvent(orderType, data)
        postPartnerizeEvent(data);
        postSuccessEvent(data);
        DataStore.clearAll();
        history.push('/success');
    };

    const postSuccessEvent = data => {
        // EX Enhanced conversions (google ads)
        pushEvent('order_complete', {
            order_id: data.code,
            order_value: data.monthlyTotal,
            order_currency: 'AUD',
            enhanced_conversion_data: {
                email: data.email
            }
        });
        
        pushGa4Event('journeyStep', {
            journeyName: orderType === TYPE_MOBILE ? 'Mobile Plans' : 'NBN Plans',
            stepName: 'Payment',
            stepNo: 8,
            referred: 'Unknown',
            smb: data.isSmb ? 'True' : 'False'
        });
    };

    if (order.mobileBundle && order.mobileBundleData && order.mobileBundleData.planId) {
        pushGa4Event('mobileBundleSubmitOrder', {
            planId: order.mobileBundleData.planId
        })
    }

    const postPartnerizeEvent = data => {
        if (partnerizeCookie) {
            pushEvent('partnerizeClickReference', {
                campaign: '1101l5667',
                clickref: partnerizeCookie,
                customerId: data.customerId,
                orderID: data.code,
                value: data.monthlyTotal,
                plan_name: data.planName
            });
        }
    };

    const resetDirectDebit = () => {
        setValue('bankName', null);
        setValue('bankBranch', null);
        setValue('bankBsb', null);
        setValue('bankAccountName', null);
        setValue('bankAccountNumber', null);
        setValue('bankAccountNumber', null);
    }

    const resetCreditCard = () => {
        setValue('creditCardNumber', null);
        setValue('creditCardHolderName', null);
        setValue('creditCardExpiry', null);
        setValue('creditCardCvc', null);
    }

    const handleChangeMethod = method => {
        setMethod(method);
        setValue('paymentMethod', method);
        trigger('paymentMethod');
        clearErrors(['creditCardHolderName', 'creditCardNumber', 'creditCardExpiry', 'creditCardCvc', 'bankName', 'bankAccountName', 'bankBsb', 'bankAccountNumber']);
        if (method === METHOD_CREDIT) {
            resetDirectDebit();
            setValue('nonce', null);
        } else if(method === METHOD_DEBIT) {
           resetCreditCard()
            setValue('nonce', null);
        } else {
            resetDirectDebit()
            resetCreditCard()
        }
    };

    const doHidePaymentForm = () => {
        setShowPaymentFormForUser(false);
        resetPaymentForm();
    };

    const handleDropInInstance = (instance) => {
        setDropInInstance(instance)
    }

    const resetPaymentForm = () => {
        setMethod(null);
        setValue('paymentMethod', null);
        setValue('bankName', null);
        setValue('bankBranch', null);
        setValue('bankBsb', null);
        setValue('bankAccountName', null);
        setValue('bankAccountNumber', null);
        setValue('bankAccountNumber', null);
        setValue('creditCardNumber', null);
        setValue('creditCardHolderName', null);
        setValue('creditCardExpiry', null);
        setValue('creditCardCvc', null);
    };

    const setShowPaymentMethodForLoggedIn = () => {
        setShowPaymentFormForUser(true);
        if (orderType === TYPE_MOBILE) {
            setMethod(METHOD_CREDIT)
        }
    }

    const getDropInUI = () => {
        if (order.conversationId) {
            return (
                <Row>
                    <p>Please capture payment details via Genesys/PCI Pal now, then continue placing order.</p>
                </Row>
            )
        }

        return (
            <DropIn handleDropInInstance={handleDropInInstance} threeDS={(isLoggedIn || isAgent) ? false: true} clientToken={clientToken} >
                <>
                    <p className="font-smaller mt-4">{`${CREDIT_CARD_FEE_TEXT} ${orderType === TYPE_MOBILE ? DIRECT_DEBIT_OPTION: ''}`}</p>
                    {
                        orderType === TYPE_MOBILE || order.mobileBundle ?
                            <p className="font-smaller">If you will have <span className="color-lime">5 (five)</span> or more prepaid services after this order, please contact our Customer Centre <br className="d-none d-lg-block"/><span className="color-lime"><a href="tel:133938">13 39 38</a></span> to complete the required identity verification.</p>
                            : ''
                    }
                </>
            </DropIn>
        );
    }

    const TermsBroadband = <span>I have read and accept the <a href="https://files.exetel.com.au/terms/Exetel-Residential-Fibre-Broadband-Terms.pdf" target="_blank" rel="noreferrer noopener">Terms & Conditions</a>, the <a href="https://files.exetel.com.au/terms/ExetelAcceptableUsePolicy.pdf" target="_blank" rel="noreferrer noopener">Acceptable Use Policy</a>, the <a href="https://files.exetel.com.au/terms/ExetelPrivacyPolicy.pdf" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="https://files.exetel.com.au/terms/CustomerServiceWaiver.pdf" target="_blank" rel="noreferrer noopener">Customer Service Guarantee Waiver</a> for my Exetel service.</span>;
    const TermsMobile = <span>I have read and accept the <a href="https://files.exetel.com.au/terms/EXE0049-R-Mobile_FA.pdf" target="_blank" rel="noreferrer noopener">Terms & Conditions</a>, the <a href="https://files.exetel.com.au/terms/ExetelAcceptableMobileUsePolicy.pdf" target="_blank" rel="noreferrer noopener">Acceptable Use Policy</a> and the <a href="https://files.exetel.com.au/terms/ExetelPrivacyPolicy.pdf" target="_blank" rel="noreferrer noopener">Privacy Policy</a> for my Exetel service.</span>;
    const TermsNbnEE = <span>I have read and accept the <a href="https://files.exetel.com.au/terms/Enterprise_ethernet_terms.pdf" target="_blank" rel="noreferrer noopener">Terms & Conditions</a>, the <a href="https://files.exetel.com.au/terms/ExetelAcceptableUsePolicy.pdf" target="_blank" rel="noreferrer noopener">Acceptable Use Policy</a>, the <a href="https://files.exetel.com.au/terms/ExetelPrivacyPolicy.pdf" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="https://files.exetel.com.au/terms/CustomerServiceWaiver.pdf" target="_blank" rel="noreferrer noopener">Customer Service Guarantee Waiver</a> for my Exetel service.</span>;
    const TermsMobileServicePrepaid = <span>I have less than 5 pre-paid mobile services and I have reviewed the <a href="https://www.exetel.com.au/mobilephone/faq-terms" target="_blank" rel="noreferrer noopener">Terms of Use</a> and <a href="https://files.exetel.com.au/cis/mobile/CIS_Mobile_Voice_PrePaid_5Plans%20-%2011.10.2023%20EX%20v1.0.pdf" target="_blank" rel="noreferrer noopener">Critical Information Summary</a> and have agreed to make this purchase.</span>;

    const authorisedChoiceSection = () => {
        return (
            <div className="mb-5">
                <InputLabel label="Transferring Your Active NBN Service" hasError={props.hasError} />
                <p className="mt-3">Are you the authorised account holder to initiate the service?</p>
                <RadioButtons options={AUTHORISED_TRANSFER_OPTIONS} register={register} name="isEndUserAuthorised" onChange={setIsEndUserAuthorised}/>
            </div>
        )
    }

    const checkForEndUserAuthorization = () => {
        if (order.activeService && order.activeService > 0) {
            return isEndUserAuthorised
        }
        return true
    }

    return (
        <div className={styles.container}>
            <LoadingOverlay isLoading={isLoading}>
                <Row className={styles.content}>
                    <Col>
                        <form onSubmit={handleSubmit(handleSubmitEvent)}>
                            <Row className="mb-4">
                                <Col>
                                    <p className="m-0 mb-2 d-lg-none">
                                        <Link to="/connection"><span className="font-smaller color-indigo underline bold">&lt; Back to Connection</span></Link>
                                    </p>
                                    <h1 className="m-0 mb-4 mb-lg-3">Payment details</h1>
                                    <div className={`${styles.separator} d-none d-lg-block`} />

                                    {/** Authorised choice */}
                                    {order.activeService && order.activeService > 0 ? authorisedChoiceSection(): ''}
                                    {/** Authorised choice */}

                                    <div className={orderType !== TYPE_MOBILE ? 'mb-5' : ''}>
                                        <h2 className="m-0 mb-2" dangerouslySetInnerHTML={{__html: noWidows('Special promo code or did a <span class="bold">friend</span> refer you?')}} />
                                        <InputField
                                            name="referrerCode"
                                            hasError={!!errors.referrerCode}
                                            defaultValue={order.referrerCode}
                                            register={register}
                                            required={false}
                                            placeholder="Special promo or referrer code"
                                        />
                                        {isAgent ?
                                            <>
                                                <p className="m-0 font-smaller underline">
                                                <Button noStyling={true}>Agent Code</Button>
                                            </p>
                                                <div className="mt-2">
                                                    <input
                                                        name="agentCode"
                                                        className={styles.input}
                                                        hasError={!!errors.agentCode}
                                                        defaultValue={agentPortalCode}
                                                        register={register}
                                                        required={false}
                                                        placeholder="EXEAGENT****"
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </>
                                            : ''}
                                    </div>

                                    {isLoggedIn ?
                                        <div className={`d-flex ${orderType !== TYPE_MOBILE ? 'mb-3' : 'mb-3 mt-5'} ${showPaymentFormForUser && orderType !== TYPE_MOBILE ? 'mb-4' : ''}`}>
                                            <div className="pe-2">
                                                <h2 className="m-0 mb-2">Payment details:</h2>
                                                <p className="m-0 font-smaller">{showPaymentFormForUser ? 'Enter your new payment details below' : 'We will use the existing payment option on your account.'}</p>
                                            </div>
                                            <div className="text-end flex-grow-1">
                                                {showPaymentFormForUser ?
                                                    <Button handleClick={doHidePaymentForm} style={{width: '100px', height: '32px', fontSize: '14px', display: 'inline-flex'}}>use existing</Button>
                                                :
                                                    <Button handleClick={setShowPaymentMethodForLoggedIn} style={{width: '70px', height: '32px', fontSize: '14px', display: 'inline-flex'}}>change</Button>
                                                }

                                            </div>
                                        </div>
                                    : ''}

                                    { (orderType !== TYPE_MOBILE && !order.mobileBundle) && (!isLoggedIn || showPaymentFormForUser) ?
                                        <div className="mb-4">
                                            <h2 className="m-0 mb-3">How would you like to pay:</h2>
                                            <div className={`${styles.methods} ${!!errors.paymentMethod ? styles.methodError : ''}`}>
                                                <div
                                                    className={`${checkAgentToEnableNonceOption() ? styles.methodWithNonce :styles.method} ${method === METHOD_CREDIT ? styles.active : ''}`}
                                                    onClick={() => handleChangeMethod(METHOD_CREDIT)}
                                                >
                                                    <img src={iconCreditCard} alt="Credit card" className="me-3" />
                                                    <div>Credit card</div>
                                                </div>
                                                <div
                                                    className={`${checkAgentToEnableNonceOption() ? styles.methodWithNonce :styles.method} ${method === METHOD_DEBIT ? styles.active : ''}`}
                                                    onClick={() => handleChangeMethod(METHOD_DEBIT)}
                                                >
                                                    <img src={iconDirectDebit} alt="Direct debit" className="me-3" />
                                                    <div>Direct debit</div>
                                                </div>
                                                {checkAgentToEnableNonceOption() && <div style={{'margin-left': '10px'}}
                                                    className={`${checkAgentToEnableNonceOption() ? styles.methodWithNonce :styles.method} ${method === METHOD_NONCE ? styles.active : ''}`}
                                                    onClick={() => handleChangeMethod(METHOD_NONCE)}
                                                >
                                                    <img src={iconCreditCard} alt="nonce" className="me-3"/>
                                                    <div>Nonce payment</div>
                                                </div>}
                                            </div>
                                            <input
                                                type="hidden"
                                                defaultValue={method}
                                                {...register('paymentMethod', { required: true })}
                                            />
                                        </div>
                                    : showPaymentFormForUser && clientToken && getDropInUI()}
                                </Col>
                            </Row>
                            {!isLoggedIn || showPaymentFormForUser  && orderType !== TYPE_MOBILE ?
                                <Row className="mb-4 position-relative">
                                    <Col>
                                        <h2 className={`m-0 ${method === METHOD_CREDIT ? '': 'mb-3'}`}>Enter your payment details:</h2>
                                        {method === null ? <div className={styles.overlay} /> : ''}
                                        {method === METHOD_DEBIT ?
                                            <DirectDebitForm errors={errors} register={register} control={control} />
                                            : method === METHOD_CREDIT ? clientToken && getDropInUI() : agentPortalCode && method === METHOD_NONCE && <NoncePaymentForm errors={errors} register={register} control={control} />
                                        }
                                    </Col>
                                </Row>
                            : ''}
                            <Row className={validationErrors ? 'mb-2' : 'mb-4'}>
                                <Col>
                                    <h2 className="m-0 mb-3">Terms and conditions:</h2>
                                    {method === METHOD_DEBIT ?
                                        <CheckboxField
                                            name="directDebitConditionsAgreed"
                                            hasError={!!errors.directDebitConditionsAgreed}
                                            defaultValue={order.directDebitConditionsAgreed}
                                            register={register}
                                            required={true}
                                            setValue={setValue}
                                            trigger={trigger}
                                        >
                                            I have reviewed and agree to the <a href="https://files.exetel.com.au/terms/Direct_Debit_Request_Service_Agreement.pdf" target="_blank" rel="noreferrer noopener">Direct Debit Service Agreement</a> and authorise payment by Direct Debit.
                                        </CheckboxField>
                                        : ''}
                                    <CheckboxField
                                        name="agreedToAllConditions"
                                        hasError={!!errors.agreedToAllConditions}
                                        defaultValue={order.agreedToAllConditions}
                                        register={register}
                                        required={true}
                                        setValue={setValue}
                                        trigger={trigger}
                                    >   
                                        {!order.isBusiness ? (orderType === TYPE_BROADBAND ? TermsBroadband : TermsMobile) : TermsNbnEE}
                                    </CheckboxField>
                                    { orderType === TYPE_MOBILE || order.mobileBundle ? <CheckboxField
                                        name="termsOfUse"
                                        hasError={!!errors.termsOfUse}
                                        register={register}
                                        required={true}
                                        setValue={setValue}
                                        trigger={trigger}
                                    >
                                        {TermsMobileServicePrepaid}
                                    </CheckboxField>: ''}

                                    {order.isBusiness ? <p>90% of Premises are available at no Fibre Build Charge, if a charge applies, you can cancel your order. We'll be in touch shortly to request extra information about your Service.</p> : ''}
                                </Col>
                            </Row>
                            {validationErrors ?
                                <Row className="mb-4">
                                    <Col>
                                        <Error errors={validationErrors} />
                                    </Col>
                                </Row>
                            : ''}

                            {/**Start Medical disclaimr section */}
                            {medicalDisclaimerData && medicalDisclaimerData.data.length > 0 ? 
                                <div className={`${styles.medicalDisclaimer} bg-grey-50 mb-5`}>
                                    <section className="flex flex-row p-3">
                                        <InfoIcon className="pe-2" />
                                        <div className="disclaimer-info">
                                            {medicalDisclaimerData.data.map((item) => {
                                                return(
                                                <p dangerouslySetInnerHTML={{__html: item}} />
                                                )
                                            })}
                                        </div>
                                    </section>
                                </div>: 
                            ''}
                            {/**End Medical disclaimr section */}

                            <BackNextButtons class="placeOrder" classNextBtn={`${!checkForEndUserAuthorization() ? 'opacity-50 not-allowed': ''}`} handleSubmit={ checkForEndUserAuthorization() ? handleSubmit(handleSubmitEvent): () => {}} handleBack={() => history.push('/connection')} title="Place order" />
                        </form>
                    </Col>
                </Row>
            </LoadingOverlay>
        </div>
    );


};

Payment.propTypes = {
    orderType: PropTypes.oneOf([TYPE_MOBILE, TYPE_BROADBAND])
};

export default Payment;
