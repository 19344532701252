import axios from "axios/index";
import DataStore from "./DataStore";
import {TYPE_BROADBAND} from "./constants";
import { handleError } from "./globals";

const DataSource = {

    getCustomer() {
        return this.getData(`${process.env.REACT_APP_API_URL}v1/me`);
    },

    updateOrder(orderType, accessToken, data, isBusiness = false) {
        let url = isBusiness ? 'order-nbn-ee' : orderType === TYPE_BROADBAND ? 'order-broadband-fibre' : 'order-mobile';
        return this.postData(`${process.env.REACT_APP_API_URL}v2/${url}`, {
            code: accessToken,
            ...data
        });
    },

    initNbnEEOrder(orderData) {        
        return this.postData(`${process.env.REACT_APP_API_URL}v2/order-nbn-ee`, orderData);
    },

    initBroadbandOrder(orderData) {        
        return this.postData(`${process.env.REACT_APP_API_URL}v2/order-broadband-fibre`, orderData);
    },

    initMobileOrder(planId, contractTerm = 0) {
        return this.postData(`${process.env.REACT_APP_API_URL}v2/order-mobile`, {
            planId: planId,
            contractTerm: contractTerm
        });
    },

    login(username, password) {
        return this.postData(`${process.env.REACT_APP_API_URL}v1/login-username-password`, {
            username: username,
            password: password
        });
    },

    requestMobileToken(mobile) {
        return this.postData(`${process.env.REACT_APP_API_URL}v1/mobile-token/request`, {mobileNumber: mobile});
    },

    requestBraintreeClientToken() {
        return this.getData(`${process.env.REACT_APP_API_URL}v1/braintree/client-token?brand=1`)
    },

    requestOAUTHToken() {
        return this.postData(`${process.env.REACT_APP_API_URL}oauth/token`)
    },

    verifyMobileToken(mobile, token) {
        return this.postData(`${process.env.REACT_APP_API_URL}v1/mobile-token/verify`, {
            mobileNumber: mobile,
            token: token
        });
    },

    getCustomerMaskedEmailAndMobile() {
        return this.getData(`${process.env.REACT_APP_API_URL}v1/mfa`)
    },

    postSendMfaCode(action) {
        return this.postData(`${process.env.REACT_APP_API_URL}v1/create-mfa`, {action: action})
    },

    verifyMfaCode(action, token){
        return this.postData(`${process.env.REACT_APP_API_URL}v1/verify-mfa`, {action: action, token:token})
    },

    getAvailablePhoneNumbers() {
        return this.getData(`${process.env.REACT_APP_API_URL}v1/available-mobile-numbers`);
    },

    searchAddress(search) {
        return this.postData('https://api.addressify.com.au/addresspro/autocomplete', {
            api_key: process.env.REACT_APP_ADDRESSIFY_KEY,
            term: search,
            info: 'true',
            address_types: 2 // 2: physical address only, 3: PO BOX address only, 1: Both physical and po box
        });
    },

    addressInfoByLocationId(locationId) {
        return this.getData(`${process.env.REACT_APP_EXPERIENCE_API_URL}v1/address/${locationId}`);
    },
    
    getRspLists() {
        return this.getData(`${process.env.REACT_APP_API_URL}v2/list-rsps?brand=exetel`);
    },

    getData(url) {
        return new Promise((resolve, reject) => {
            fetch(url, this.getConfig())
                .then(result => {
                    if (!result.ok) {
                        throw new Error(`HTTP status ${result.status} from GET request URL ${url}`);
                    }

                    return result.json();
                })
                .then(result => {
                    resolve(result);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    postData(url, data = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: url,
                data: data,
                ...this.getConfig()
            })
                .then(response => {
                    resolve(response);
                })
                .catch(response => {
                    this.handleErrors(url, response);
                    reject(response);
                });
        });
    },

    logApiEvent(event) {
        return this.postData(`${process.env.REACT_APP_EXPERIENCE_API_URL}v1/event-management/events`, event);
    },

    getConfig() {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        const bearerTokenObj = DataStore.getItem('bearerToken')
        if (bearerTokenObj) {
            const bearerToken = (typeof bearerTokenObj === 'object') ? bearerTokenObj.value: bearerTokenObj
            headers.Authorization = `Bearer ${bearerToken}`;
        }

        return {
            headers: headers
        };
    },

    handleErrors(url, response) {
        if (response && ((response.response && response.response.status !== 422 && response.response.status !== 401) || !response.response)) {
            handleError(response.message, {url: url});
        }
    },

}

export default DataSource;