import React from "react";
import PropTypes from "prop-types";
import styles from "../YesNoField.module.scss";
import InputLabel from "../../InputLabel/InputLabel";


const YesNoFieldV2 = (props) => {

    return (
        <div className={`${props.classes && props.classes} ${props.hasError ? styles.error : ''} mb-3`}>
            {props.label && <InputLabel label={props.label} hasError={props.hasError} />}
            {React.Children.map(props.children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child)
                }
            })}
        </div>
    );

};

const YesButton = (props) => {
    return (
        <div
            className={`${styles.button} ${props.classNames && props.classNames} ${(props.value === true || props.value === 1) ? styles.active : ''}`}
            onClick={props.handleChange }
        >                    
            {props.children}
        </div>
    )
}

YesNoFieldV2.YesButton = YesButton

const NoButton = (props) => {
    return (
        <div
            className={`${styles.button} ${props.classNames && props.classNames} ${(props.value === false || props.value === 0) ? styles.active : ''}`}
            onClick={props.handleChange }
        >                    
            {props.children}
        </div>
    )
}

YesNoFieldV2.NoButton = NoButton

YesNoFieldV2.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,
    required: PropTypes.bool,
    hasError: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default YesNoFieldV2;