import React from "react";
import styles from "./Banner.module.scss";
import PropTypes from "prop-types";


const Banner = (props) => {
    return (
        <div className={styles.bannerRoot} key={props.key}>
            <p className={styles.bannerContent}>
                {props.content}
            </p>
        </div>
    )
}

Banner.propTypes = {
    content: PropTypes.element,
    key: PropTypes.any
}

export default Banner;