import React, { useEffect, useState, useContext } from "react";
import DataSource from "../../DataSource";
import MfaCodeForm from "./MfaCodeForm";
import MfaForm from "./MfaForm";
import styles from "./Mfa.module.scss";
import { useHistory } from "react-router-dom";
import OrderContext from "../../OrderContext";
import { pushBeginCheckoutEvent, pushJourneyEventSignIn} from "../../events";
import { Col, Row } from "react-bootstrap";
import LoadingOverlay from "../../layout/LoadingOverlay/LoadingOverlay";
import DataStore from "../../DataStore";

export const Mfa = (props) => {

    const { order, updateOrder, setIsLoggedIn, orderType } = useContext(OrderContext);
    const [showCodeForm, setShowCodeForm] = useState(false)
    const [maskedData, setMaskedData] = useState({})
    const [error, setError] = useState('')
    const [action, setAction] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();
 
   useEffect(() => {
    handleGetMaskedData()
    }, [])

    const handleGetMaskedData = async() => {
        try{
            const response = await DataSource.getCustomerMaskedEmailAndMobile()
        if(response) {
            setMaskedData({
                mobileNumber: response.data.mobile,
                email: response.data.email
            })
        }
        }catch(exception) {
            if (exception?.response) {
                setError(exception?.response?.data?.message)
            }
        }
    }

    const handleSendMFACode = async(mfaAction) => {
        try{
            const response = await DataSource.postSendMfaCode(mfaAction ? mfaAction: action)
            if(response) {
                setShowCodeForm(true)
                setAction(mfaAction)
            }
        }catch(exception) {
            if (exception?.response) {
                setError(exception?.response?.data?.message)
            }
        }
    }
    
    const handleVerifyMFACode = async(code) => {
        try {
            setIsLoading(true)
            const response = await DataSource.verifyMfaCode(action, code)
            if(response?.data?.valid) {
                getCustomerDetails()
            } else {
                setIsLoading(false)
                setError('Something went wrong, Please try again!')
            }
        }catch(exception) {
            setIsLoading(false)
            if (exception?.response) {
                setError(exception?.response?.data?.message)
            }
        }
    }
    
    const getCustomerDetails = () => {
        DataSource.getCustomer().then((response) => {
            DataStore.setItem("sessionUserMFA", true)
            updateOrder({
                salutation: response.data.title,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                email: response.data.contactEmail,
                contact: response.data.contactMobile,
                completed: false,
                validIdCheck: response.data.validIdCheck
            }).then(() => {
                setIsLoading(false)
                setIsLoggedIn(true);
                pushJourneyEventSignIn(response.data, orderType);
                pushBeginCheckoutEvent(order, orderType);
                history.push("/connection");
            });
        })
        .catch((error) => {
            setIsLoading(false)
            if (error.response.status === 422) {
                setError(error.response.data.errors);
            } else if (error.response.status === 401) {
                setError("Your login was unsuccessful, please try again.");
            } else {
                setError("Oops, something went wrong, please try again.");
            }
            props.setIsLoading(false);
        });
    }
    

    return (
       <LoadingOverlay isLoading={isLoading}>
        <Row>
            <Col lg={6} className={styles.content}>
            <p className="bold font-xlarge mb-lg-5 mb-3">Verify your account</p>
            { !showCodeForm ? 
                <MfaForm 
                 mobileNumber={maskedData.mobileNumber}
                 emailAddress={maskedData.email}
                 handleSubmit={handleSendMFACode}
                 error={error}
                />
            : <MfaCodeForm handleSubmitMfa={handleVerifyMFACode} handleResendCode={() => handleSendMFACode(action)} error={error}/>        
            }
            </Col>
       </Row>
       </LoadingOverlay>
    )
}