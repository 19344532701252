import React from "react";
import { Modal } from "react-bootstrap";
import './ReactModal.scss';

const ReactModal = (props) => {
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName={props.contentClassName && props.contentClassName}>
            <Modal.Header closeButton bsPrefix={props.headerClassName && props.headerClassName} closeVariant={props.closeVariant && props.closeVariant}>
                {props.heading}
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>            
        </Modal>
    );
};

export default ReactModal;
