import React from 'react'

export const InfoIcon = (props) => {
    return (
        <i {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 9.27273C12.5021 9.27273 12.9091 9.67974 12.9091 10.1818V16.5455C12.9091 17.0475 12.5021 17.4545 12 17.4545C11.4979 17.4545 11.0909 17.0475 11.0909 16.5455V10.1818C11.0909 9.67974 11.4979 9.27273 12 9.27273Z" fill="#7422FF"/>
                <path d="M12 8.36364C12.5021 8.36364 12.9091 7.95662 12.9091 7.45455C12.9091 6.95247 12.5021 6.54545 12 6.54545C11.4979 6.54545 11.0909 6.95247 11.0909 7.45455C11.0909 7.95662 11.4979 8.36364 12 8.36364Z" fill="#7422FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818Z" fill="#7422FF"/>
            </svg>
        </i>
    )
}