export const isTokenValid = (expirationTimestamp) => {
    const currentTimestamp = Math.floor(Date.now() / 1000)
 
    return expirationTimestamp > currentTimestamp
}

export const agentLogout = () => {
    localStorage.removeItem('orderFormToken');
    localStorage.removeItem('agentUsername');
    localStorage.removeItem('expiry');
    localStorage.removeItem('agentCode');
    localStorage.setItem('isAgent', 'false'); 
}