import DataSource from "../../../DataSource"

export const getRspLists = async () => {
    try {
        let response = []
        const result = await DataSource.getRspLists()
        if (result.data && result.data.length > 0) {
            response = result.data.map((item) => {
                return {label: item.display_name, value: item.id}
            })
        }
        return response
    } catch (error) {
        throw(error)
    }
}