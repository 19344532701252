import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import InputField from '../InputField/InputField';
import Close from '../../images/icon-close.svg';
import styles from './ManualInput.module.scss';
import SelectField from '../SelectField/SelectField';

const ManualInput = (props) => {

    const { register, handleSubmit, formState: { errors } } = useForm();
    
    const onSubmit = data => {
        const { unitNumber, streetNumber, streetName, streetType, suburb, postcode, state } = data;        
        const unit = unitNumber !== "" ? 'UNIT ' + unitNumber : "";   
        let address = unit + " " + streetNumber + " " + streetName + " " + streetType + ", " + suburb + " " + state + " " + postcode;                
        props.setValue(props.name, address);
        props.handleClose();
    }

    const mapDataToList = (list) => {
        let data = [];
        list.forEach(item => {
            data.push({title: item, value: item});
        });

        return data;
    }

    return (
        <div className={styles.container}>
            <div className="d-flex justify-content-between align-items-center">
                <p className="bold m-0 font-small">Manually enter your address below:</p>
                <a className='font-tiny d-flex' href="#" onClick={props.handleCloseManualInput}>
                    <img src={Close} alt="close-btn" />
                    close search
                </a>
            </div>
            <div className={`${styles.form} mt-4`}>
                <Row>
                    <Col md={6}>
                        <InputField
                            label="Unit Number:"
                            name="unitNumber"
                            hasError={!!errors.unitNumber}
                            register={register}
                            required={false}                            
                        />                        
                    </Col>
                    <Col md={6}>
                        <InputField
                            label="Street Number:"
                            name="streetNumber"
                            hasError={!!errors.streetNumber}
                            register={register}
                            required={true}                            
                        />
                    </Col>
                    <Col md={6}>
                        <InputField
                            label="Street Name:"
                            name="streetName"
                            hasError={!!errors.streetName}
                            register={register}
                            required={true}                            
                        />                        
                    </Col>
                    <Col md={6}>
                        <SelectField 
                            label="Street Type:"
                            name="streetType"
                            hasError={!!errors.streetType}                            
                            register={register}
                            items={mapDataToList(streetTypeList)}
                            required={true}
                            placeholder="Select street type"
                        /> 
                    </Col>
                    <Col md={6}>
                        <InputField
                            label="Suburb:"
                            name="suburb"
                            hasError={!!errors.suburb}
                            register={register}
                            required={true}                            
                        />                        
                    </Col>
                    <Col md={3}>
                        <InputField
                            label="Postcode"
                            name="postcode"
                            hasError={!!errors.postcode}                            
                            required={true}                            
                            type="postcode"
                            maxLength={4}
                            register={register}
                            pattern={/^(0[289][0-9]{2})|([1-9][0-9]{3})$/}
                        />                        
                    </Col>
                    <Col md={3}>
                        <SelectField 
                            label="State"
                            name="state"
                            hasError={!!errors.state}                            
                            register={register}
                            items={mapDataToList(state)}
                            required={true}
                            placeholder="Select state"
                        />                       
                    </Col>
                    <Col md={12} className="my-3">
                        <button className={styles.button} onClick={handleSubmit(onSubmit)}>Enter address</button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const state = ['NSW', 'QLD', 'SA', 'TAS', 'VIC', 'WA', 'ACT', 'NT'];

const streetTypeList = [
    "Ally",
    "Arc",
    "Ave",
    "Bvd",
    "Bypa",
    "Cct",
    "Cl",
    "Crn",
    "Ct",
    "Cres",
    "Cds",
    "Dr",
    "Esp",
    "Grn",
    "Gr",
    "Hwy",
    "Jnc",
    "Lane",
    "Link",
    "Mews",
    "Pde",
    "Pl",
    "Rdge",
    "Rd",
    "Sq",
    "St",
    "Tce"
]

export default ManualInput