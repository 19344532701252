import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./CheckboxField.module.scss";
import iconTick from "../../images/icon-tick.svg";

const CheckboxField = (props) => {

    const [isChecked, setIsChecked] = useState(props.defaultValue);

    const handleClick = value => {
        setIsChecked(value);
        props.setValue(props.name, value);
        props.trigger(props.name);
        if (props.handleClick) {
            props.handleClick(value);
        }
    };

    return (
        <div className={`${styles.container} ${props.hasError ? styles.error : ''} mb-3`} style={props.style}>
            <div className={styles.checkboxContainer}>
                <div
                    className={`${styles.checkbox} ${isChecked ? styles.active : ''} ${props.isLarge ? styles.large : ''}`}
                    onClick={() => handleClick(!isChecked)}
                >
                    {isChecked ? <img src={iconTick} alt="Checked" /> : ''}
                </div>
                <input
                    type="checkbox"
                    style={{display: 'none'}}
                    {...props.register(props.name, { required: props.required })}
                />
            </div>
            <label className={styles.label}>{props.children}</label>
        </div>
    );

};

CheckboxField.propTypes = {
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    required: PropTypes.bool,
    handleClick: PropTypes.func,
    defaultValue: PropTypes.bool,
    setValue: PropTypes.func.isRequired,
    trigger: PropTypes.func.isRequired,
    style: PropTypes.object,
    isLarge: PropTypes.bool
};

CheckboxField.defaultProps = {
    handleClick: () => {},
    style: {}
};

export default CheckboxField;