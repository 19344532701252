import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import InputField from "../InputField/InputField";
import OrderContext from "../../OrderContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import DataSource from "../../DataSource";
import Error from "../Error/Error";
import DataStore from "../../DataStore";
import {HS_SUBSCRIBE, HS_TRIAL} from "../../constants";
import { useSelector } from "react-redux";


const Login = (props) => {

    const { order, updateOrder, setIsLoggedIn } = useContext(OrderContext);
    const { register, handleSubmit, formState: { errors }} = useForm();
    const [error, setError] = useState('');
    const history = useHistory();
    const upsell = useSelector(state => state.upsell.value);

    const handleSubmitEvent = (data) => {
        setError("");
        props.setIsLoading(true);

        // Update the mobile upsell into the main order object on submit
        data.mobileBundle = upsell.mobileBundle;
        data.mobileBundleData = upsell.mobileBundleData;

        DataSource.login(data.username, data.password)
            .then((response) => {
                props.setIsLoading(false);
                DataStore.setItem("bearerToken", response.data.access_token);
                const sessionUserId = DataStore.getItem('sessionUserId')
                const sessionUserMFA = DataStore.getItem('sessionUserMFA')
                
                if (sessionUserId === response.data.user_id && sessionUserMFA) {
                    getCustomer()
                } else {
                    DataStore.setItem("sessionUserId", response.data.user_id)
                    history.push("/mfa");
                }
            })
            .catch((error) => {
                if (error.response.status === 422 || error.response.status === 403 || error.response.status === 401 || error.response.status === 429) {
                    if (error.response.data.errors) {
                        setError(error.response.data.errors);
                    } else {
                        setError(error.response.data.message)
                    }
                } else if (error.response.status === 401) {
                    setError("Your username and/or password are incorrect.");
                } else {
                    setError("Oops, something went wrong, please try again.");
                }
                props.setIsLoading(false);
            });
    };

    const handleContinue = () => {
        getCustomer()
    };

    const getCustomer = () => {
        DataSource.getCustomer().then((response) => {
            updateOrder({
                salutation: response.data.title,
                firstName: response.data.firstName,
                lastName: response.data.lastName,
                email: response.data.contactEmail,
                contact: response.data.contactMobile,
                completed: false,
                validIdCheck: response.data.validIdCheck
            }).then(() => {
                setIsLoggedIn(true);
                history.push("/connection");
            });
        })
        .catch((error) => {
            if (error.response.status === 422) {
                setError(error.response.data.errors);
            } else if (error.response.status === 401) {
                setError("Your login was unsuccessful, please try again.");
            } else {
                setError("Oops, something went wrong, please try again.");
            }
            props.setIsLoading(false);
        });
    }

    const handleSignOut = () => {
        DataStore.setItem("bearerToken", null);
        updateOrder({
            firstName: "",
            lastName: "",
            email: "",
        }).then(() => {
            setIsLoggedIn(false);
        });
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit(handleSubmitEvent)();
        }
    };

    const LoginForm = () => {
        return (
            <form onSubmit={handleSubmit(handleSubmitEvent)}>
                <Row className="mb-4">
                    <Col>
                        <h2 className="m-0 mb-3">Login to your account:</h2>
                        <InputField
                            label="Username"
                            subLabel="Email or Phone number"
                            name="username"
                            hasError={!!errors.username}
                            defaultValue={order.username}
                            register={register}
                            required={true}
                            type="email"
                            autoComplete="username"
                        />
                        <InputField
                            label="Password"
                            name="password"
                            hasError={!!errors.password}
                            defaultValue={order.password}
                            register={register}
                            required={true}
                            type="password"
                            autoComplete="current-password"
                            handleKeyDown={handleKeyDown}
                        />
                    </Col>
                    {error ? (
                        <Col lg={12}>
                            <Error errors={error} />
                        </Col>
                    ) : (
                        ""
                    )}
                </Row>
                <Button handleClick={handleSubmit(handleSubmitEvent)}>
                    Login
                </Button>
                <p className="m-0 mt-4 text-center">
                    <small>
                        Forgotten your{" "}
                        <a
                            href={`${process.env.REACT_APP_FORGOT_USERNAME_URL}/forgot-username`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <span className="bold">username</span>
                        </a>{" "}
                        or{" "}
                        <a
                            href={`${process.env.REACT_APP_FORGOT_USERNAME_URL}/forgot-password`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <span className="bold">password</span>
                        </a>
                        ?
                    </small>
                </p>
            </form>
        );
    };

    const LoginContinue = () => {
        return (
            <React.Fragment>
                <Row className="mb-4">
                    <Col className="d-flex align-items-center">
                        <div className="me-5">
                            <h2 className="m-0 mb-3">Existing account:</h2>
                            <p
                                className="m-0 mb-1"
                                style={{ textTransform: "uppercase" }}
                            >
                                {order.firstName} {order.lastName}
                            </p>
                            <p className="m-0">{order.email}</p>
                        </div>
                        <div>
                            <Button
                                handleClick={handleSignOut}
                                style={{
                                    width: "70px",
                                    height: "32px",
                                    fontSize: "14px",
                                }}
                            >
                                change
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Button handleClick={handleContinue}>Continue</Button>
            </React.Fragment>
        );
    };

    if (props.isLoggedIn) {
        return LoginContinue();
    }

    return LoginForm();
};

Login.propTypes = {
    isLoggedIn: PropTypes.bool,
    setIsLoading: PropTypes.func.isRequired,
    homeSecurePlan: PropTypes.oneOf([HS_SUBSCRIBE, HS_TRIAL]).isRequired,
};

export default Login;
