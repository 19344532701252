import React from "react";
import PropTypes from "prop-types";
import InputField from "../InputField/InputField";

const NoncePaymentForm = (props) => {

    return (
        <React.Fragment>
            <InputField
                label="Nonce string"
                name="nonce"
                hasError={!!props.errors.nonce}
                register={props.register}
                required={true}
                placeholder="nonce string"
                maxlength = "50"
            />
        </React.Fragment>
    );

};

NoncePaymentForm.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired
};

export default NoncePaymentForm;