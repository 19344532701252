import React, {useEffect} from "react";
import  './DropIn.css';
import PropTypes from "prop-types";

const WebDropIn = require('braintree-web-drop-in');

const DropIn = (props) => {
    useEffect(() => {
        WebDropIn.create({
            authorization: props.clientToken,
            container: '#dropin-container',
            card: {
                cardholderName: {
                    required: true,
                },
            },
            threeDSecure: props.threeDS
        }, function (createErr, instance) {
            props.handleDropInInstance(instance);
        });
    }, [])
    return (
        <div className="braintree-dropin">
            <div id="dropin-container" />
            {props.children}
        </div>
    )
}

DropIn.propTypes = {
    handleDropInInstance: PropTypes.func.isRequired,
    note: PropTypes.element,
    threeDS: PropTypes.bool
};

export default DropIn