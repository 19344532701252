import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { updateUpsell } from "../../features/upsellSlice";
import styles from "./UpsellMobileBundle.module.scss";
import homeSecure from "../../images/logo-home-secure.svg";
import LoadingOverlay from "../../layout/LoadingOverlay/LoadingOverlay";
import Carousel from "react-elastic-carousel";
import OrderContext from "../../OrderContext";
import { SIM_TYPE_3IN1 } from "../../constants";
import UpsellPlanCard from "../UpsellPlanCard/UpsellPlanCard";
import {pushGa4Event} from "../../globals";

const UpsellMobileBundle = (props) => {
    const { updateOrderNotState, setIsUpsellMobileBundleSelected } = useContext(OrderContext);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleClickRemove = () => {
        let data = {
            mobileBundle: false,
            mobileBundleData: null,
        };
        setIsLoading(true);
        setIsUpsellMobileBundleSelected(false);
        updateOrderNotState(data).then((response) => {
            dispatch(updateUpsell({ ...response.data.data.request, ...data }));
            setIsLoading(false);
        });
    };

    const handleAddToCart = (plan) => {
        let data = {
            mobileBundle: true,
            mobileBundleData: {
                planId: plan.id,
                simType: SIM_TYPE_3IN1,
                planName: plan.title,
                includedData: plan.data,
                price: plan.price,
                serviceAddress: "",
                deliveryName: "",
                newMobileNumber: "",
                portNumber: false,
                promoCredit: 0,
                features: [],
            },
        };
        setIsLoading(true);
        setIsUpsellMobileBundleSelected(true);
        updateOrderNotState(data).then((response) => {
            dispatch(updateUpsell({ ...response.data.data.request, ...data }));
            setIsLoading(false);
        });
        pushGa4Event('mobileBundleAddPlan', {
            planId: plan,
        });
    };

    if (!props.upsellModule || props.isLoading) {
        return '';
    }

    return (
        <div className={`${styles.container} mt-4`}>
            <LoadingOverlay isLoading={isLoading}>
                <div className={styles.contentCard}>
                    <img src={homeSecure} alt="Home secure" className={styles.logo} />
                    <div className={styles.contentCard}>
                        <p className={`w-75 bold font-larger`}>Bundle ExeSIM mobile & save $5/mth</p>
                        <p className={`w-75`}>
                            With our SLASH MY BILL discounts, the more products you bundle with Exetel, the more you save.
                        </p>
                        {props.upsellModule?.data.plans.length > 0 ? (
                            <Carousel showArrows={false} isRTL={false} initialActiveIndex={1}>
                                {props.upsellModule?.data.plans.map((plan, key) => {
                                    return (
                                        <div className={styles.plan} key={key}>
                                        <UpsellPlanCard
                                            plan={plan}
                                            handleAddToCart={handleAddToCart}
                                            handleClickRemove={handleClickRemove}
                                            />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    );
};

UpsellMobileBundle.propTypes = {
    upsellModule: PropTypes.object,
    isLoading: PropTypes.bool
};

export default UpsellMobileBundle;
