import {
    CATEGORIES,
    CONVERSION_F2F, CONVERSION_FIBRE,
    CONVERSION_MOBILE, CONVERSION_NBN,
    CONVERSION_SMB_NBN, PROVIDER_NBN,
    TYPE_BROADBAND,
    TYPE_MOBILE
} from "../constants";

export const mapConversionType = (orderType, order) => {
    if (orderType === TYPE_MOBILE) {
        return CONVERSION_MOBILE
    } else if (orderType === TYPE_BROADBAND) {
        if (order.isSmb) {
            return CONVERSION_SMB_NBN
        } else if (!order.isSmb && order.isFlip) {
            return CONVERSION_F2F
        } else if (order.provider !== PROVIDER_NBN) {
            return CONVERSION_FIBRE
        }
    }

    return CONVERSION_NBN
}

export const mapCategory2 = (orderType, order) => {
    if (orderType === TYPE_MOBILE) {
        return CONVERSION_MOBILE
    } else if (orderType === TYPE_BROADBAND) {
        if (order.isSmb) {
            if (order.category && CATEGORIES.includes(order.category)) {
                return order.category
            } else {
                return CONVERSION_NBN
            }
        } else if (!order.isSmb && order.isFlip) {
            return CONVERSION_F2F
        } else if (order.provider !== PROVIDER_NBN) {
            return CONVERSION_FIBRE
        }
    }

    return CONVERSION_NBN
}

export const buildEcommerceItems = (orderType, order) => {
    let items = [];

    if (orderType === TYPE_MOBILE) {
        items.push(buildMobileItem(order))
    } else if (orderType === TYPE_BROADBAND) {
        items.push(buildBroadbandItem(orderType, order))
        if (order.mobileBundle) {
            items.push(buildBundledMobileItem(order))
        }
        if (order.paygVoip || order.unlimitedPhone) {
          items.push(buildVoipEvent(order))
        }
        if (order.eeroModem || order.upfrontEero || order.upfrontModem) {
          items.push(buildModemEvent(order))
        }
        if (order.extenderModem) {
            items.push(buildExtenderEvent(order))
        }
    }

    return items;
}

const checkForBundleOrder = (order) => {
    return order.mobileBundle || order.paygVoip || order.unlimitedPhone || order.eeroModem || order.upfrontEero || order.upfrontModem || order.extenderModem
}

export const buildBroadbandItem = (orderType, order) => {
    return {
        //item_id: order.planId, // Hide to prioritise planName display
        item_name: order.planName,
        item_category: order.isSmb ? 'Business' : 'Residential',
        item_category2: mapCategory2(orderType, order),
        item_category3: checkForBundleOrder(order) ? 'Bundle' : '',
        // For bundled items, for some reason there is a different value for price (if the item is discounted)
        price: order.internetCost ? parseFloat(order.internetCost) : parseFloat(order.broadbandPlanCost),
        currency: 'aud',
        quantity: 1
    }
}

export const buildMobileItem = (order) => {
    return {
        //item_id: order.planId, // Hide to prioritise planName display
        item_name: order.planName,
        item_category: order.isSmb ? 'Business' : 'Residential',
        item_category2: CONVERSION_MOBILE,
        price: parseFloat(order.monthlyTotal),
        currency: 'aud',
        quantity: 1
    }
}

export const buildBundledMobileItem = (order) => {
    return {
        //item_id: order.mobileBundleData.planId, // Hide to prioritise planName display
        item_name: order.mobilePlanName,
        item_category: order.isSmb ? 'Business' : 'Residential',
        item_category2: CONVERSION_MOBILE,
        item_category3: order.mobileBundle ? 'Bundle' : '',
        price: parseFloat(order.mobileMonthlyCost),
        currency: 'aud',
        quantity: 1
    }
}

export const buildVoipEvent = (order) => {
    return {
        item_name: `${order.isSmb ? "Unlimited Business Phone": order.paygVoip ? 'VoIP Pay-as-you-go' : 'VoIP Unlimited'}`,
        item_category: order.isSmb ? 'Business' : 'Residential',
        item_category2: (order.isSmb ? 'Business' : 'Home') + " phone",
        item_category3: 'Bundle',
        price: order.paygVoip ? 0 : 10,
        currency: 'aud',
        quantity: 1
    }
}

export const buildModemEvent = (order) => {
    let name
    if (order.eeroModem || order.upfrontEero) {
        name = `${parseFloat(order.eeroCost) > 0 ? '' : 'Free '}Eero 6+`
    }
    if (order.upfrontModem) {
        name = `${parseFloat(order.modemCost) > 0 ? '' : 'Free '}ZTE H1600`
    }

    return {
        item_name: name,
        item_category: order.isSmb ? 'Business' : 'Residential',
        item_category2: "Router",
        item_category3: "Bundle",
        price: order.modemCost, // Not adding price since its one-off cost
        currency: 'aud',
        quantity: 1
    }
}

export const buildExtenderEvent = (order) => {
    return {
        item_name: order.eeroExtender ? 'Eero extender' : 'ZTE h3601 extender',
        item_category: order.isSmb ? 'Business' : 'Residential',
        item_category2: "Extender",
        item_category3: "Bundle",
        price: order.extenderCost,
        currency: 'aud',
        quantity: order.extenderQuantity,
    }
}

