import React from "react";
import PropTypes from "prop-types";
import InputField from "../InputField/InputField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const DirectDebitForm = (props) => {

    return (
        <React.Fragment>
            <InputField
                label="Name of bank"
                name="bankName"
                hasError={!!props.errors.bankName}
                register={props.register}
                required={true}
                placeholder="ie. Commonwealth Bank"
            />
            <InputField
                label="Bank branch"
                name="bankBranch"
                hasError={!!props.errors.bankBranch}
                register={props.register}
                required={true}
                placeholder="ie. Bondi Junction"
            />
            <InputField
                label="Account name"
                name="bankAccountName"
                hasError={!!props.errors.bankAccountName}
                register={props.register}
                required={true}
                placeholder="Account holders name"
            />
            <Row>
                <Col lg={6}>
                    <InputField
                        label="BSB"
                        name="bankBsb"
                        hasError={!!props.errors.bankBsb}
                        register={props.register}
                        required={true}
                        type="bankBsb"
                        placeholder="123456"
                        control={props.control}
                    />
                </Col>
                <Col lg={6}>
                    <InputField
                        label="Account number"
                        name="bankAccountNumber"
                        hasError={!!props.errors.bankAccountNumber}
                        placeholder="12345678"
                        register={props.register}
                        required={true}
                        maxLength={10}
                        type="phone"
                    />
                </Col>
            </Row>
        </React.Fragment>
    );

};

DirectDebitForm.propTypes = {
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired
};

export default DirectDebitForm;