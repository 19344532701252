const providers = [
    "AAPT",
    "Accounts Choice",
    "ACN",
    "Activ8me",
    "Adam Internet",
    "ALDImobile",
    "Amaysim",
    "Aussie Broadband",
    "Apex Telecom",
    "Austar",
    "BDigital",
    "Bell Networks",
    "Belong",
    "Bendigo Bank Telco",
    "Blink",
    "Boost Mobile",
    "BT Telecom",
    "Bureau Holdings",
    "Catch Connect",
    "Circles.Life",
    "Cellhire",
    "Charitel",
    "ClubTelco",
    "CMobile",
    "Coles Mobile",
    "Commander",
    "Comms Choice",
    "Community Telco",
    "Connex",
    "Crazy Johns",
    "CTExcelbiz",
    "Dodo",
    "Down Under Communica",
    "E.Tel",
    "Engin",
    "Excite Mobile",
    "Exetel",
    "Felix mobile",
    "Flexirent",
    "Global Gossip",
    "Global Mobility Grou",
    "Gomo",
    "GoTalk",
    "GTMobile",
    "Hello Mobile",
    "Hutchison3",
    "iiNet",
    "Internode",
    "iPrimus",
    "iSeek",
    "ISPOne",
    "iTalkBB",
    "Jeenee Mobile",
    "Just Mobile",
    "Kidney Australia",
    "KISA Phone",
    "Kiss Mobile",
    "Kogan Mobile",
    "Lebara Mobile",
    "Live Connected",
    "Lycamobile",
    "MATE",
    "Moose Mobile",
    "numobile",
    "M2 Telecom",
    "Macquarie Telecom",
    "MyNetFone",
    "Neptune",
    "ONEmobile",
    "OneSeniors",
    "Optus Post Paid",
    "Optus PrePaid",
    "Oracle Telecom",
    "Pacnet",
    "PennyTel",
    "People Telecom",
    "Perfection Telecom",
    "PhoneStar/Spa",
    "Planet Mobile",
    "Primus Telecom",
    "Quality Telecom",
    "RealSIM",
    "Red Bull Mobile",
    "Revolution Telecom",
    "Reward Mobile",
    "Savvytel",
    "SlimTel",
    "Soul",
    "Southern Cross Telco",
    "Southern Phone",
    "SpinTel",
    "Startel",
    "Sure Telecom",
    "Switched On",
    "Switchtelco",
    "Tangerine Telecom",
    "Telair",
    "Telarus",
    "Telco Green",
    "Telco In A Box",
    "Telco4U",
    "TeleChoice",
    "Telstra",
    "Think Mobile",
    "TPG",
    "TransACT",
    "Truphone",
    "Ugly Bill",
    "untimedmobiles.com.a",
    "Vaya",
    "Virgin Mobile",
    "Vodafone",
    "Westnet",
    "Woolworths Connect",
    "Yatango Mobile",
    "Yomojo"
];

export default providers;
