import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Addressify.module.scss";
import DataSource from "../../DataSource";
import InputLabel from "../InputLabel/InputLabel";
import iconError from "../../images/icon-error.svg";
import iconClose from "../../images/icon-close.svg";
import { handleError } from "../../globals";
import ManualInput from "../ManulInput/ManualInput";

const Addressify = (props) => {

    //todo: Show loader when loading addresses
    const [isLoading, setIsLoading] = useState(false);
    const [addresses, setAddresses] = useState(null);
    const [addressInput, setAddressInput] = useState("");
    const [showManualInput, setShowManualInput] = useState(false);

    const doSearch = () => {
        setIsLoading(true);
        if (addressInput !== "") {
            DataSource.searchAddress(addressInput)
                .then(response => {            
                    setAddresses(response.data);
                })
                .catch(error => {
                    handleError(`Error occurred when trying to search for address "${addressInput}"`, error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    useEffect(() => {        
        const timer = setTimeout(() => {
            doSearch();
            if (props.setIsAddressSelected) {
                props.setIsAddressSelected(false)
            }                 
        }, 800);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [addressInput]);

    useEffect(() => {
        setShowManualInput(false);
    }, [addressInput]);

    const handleSelect = address => {
        setAddresses(null);
        props.setValue(props.name, address);
        if (props.setIsAddressSelected) {
            props.setIsAddressSelected(true)
        }
    };

    const handleClose = () => {        
        setAddresses(null);
    };

    // const handleShowManualInput = (e) => {
    //     e.preventDefault();
    //     setShowManualInput(true);
    // }

    const handleCloseManualInput = (e) => {
        e.preventDefault();
        setShowManualInput(false);
    }

    // const handleResetManualSearch = (e) => {
    //     e.preventDefault();        
    //     setShowManualInput(true);        
    // }

    const AddressData = () => {
        if (addresses && addresses.length > 0) {
            return (
                <>
                    {addresses.slice(0,6).map((item, key) => {
                        return <button
                            key={key}
                            className={styles.link}
                            onClick={() => handleSelect(item.AddressFull)}
                        >
                            {item.AddressFull}
                        </button> ;
                    })}
                </>
            );
        }

        if (addresses !== null && addresses.length === 0) {
            return (
                <React.Fragment>
                    <div className={styles.noLink}>No addresses found</div>
                    <div className={styles.close} onClick={handleClose}>
                        <img src={iconClose} alt="Close" />
                    </div>
                </React.Fragment>
            );
        }        
    };

    return (
        <div className={`${styles.container} ${props.hasError ? styles.error : ''} mb-3`}>
            {props.label ? <InputLabel label={props.label} subLabel={props.subLabel} hasError={props.hasError} /> : ''}
            <div className={styles.inner} style={props.hasError ? {paddingRight: '30px'} : {}}>
                <input
                    type="text"                    
                    className={styles.input}                    
                    onKeyPress={event => setAddressInput(event.target.value)}
                    onFocus={event => event.target.select()}
                    required={true}
                    defaultValue={props.defaultValue}                    
                    {...props.register(props.name, { required: props.required })}
                />
                {props.hasError ?
                    <div className={styles.errorIcon}>
                        <img src={iconError} alt="Error" />
                    </div>
                : ''}
                {addresses !== null && !isLoading ?
                    <div className={styles.results}>
                        {!showManualInput ? <AddressData /> : <ManualInput handleCloseManualInput={handleCloseManualInput} setValue={props.setValue} handleClose={handleClose} name={props.name} />}                        
                    </div>
                : ''}
            </div>
        </div>
    );
    

};

Addressify.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    hasError: PropTypes.bool,
    name: PropTypes.string,
    register: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    required: PropTypes.bool,
    defaultValue: PropTypes.string,
};

export default Addressify;