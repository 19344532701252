import React from 'react'
import styles from './RadioButton.module.scss'

const RadioButtons = (props) => {
    const handleOnChange = (value) => {
        props.onChange(value)
    }
    return (
        <section className='flex flex-row align-items-center'>
            {props.options && props.options.map((item) =>  {
                return (
                    <section className='flex flex-row me-4'>
                        <input 
                          class={`${styles.formCheckInput}`} type="radio" 
                          id="flexRadioDefault1"
                          onClick={() => handleOnChange(item.value)}
                          {...props.register(props.name, { required: props.required })}
                        />
                        <label class="form-check-label mx-2 align-items-center" for="flexRadioDefault1">
                            {item.label && item.label}
                        </label>
                    </section>     
                )}
            )}
        </section>
    )
}

export default RadioButtons