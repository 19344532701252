import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import styles from "./Account.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NewAccount from "../../components/NewAccount/NewAccount";
import Login from "../../components/Login/Login";
import LoadingOverlay from "../../layout/LoadingOverlay/LoadingOverlay";
import {HS_SUBSCRIBE, HS_TRIAL} from "../../constants";
import OrderContext from "../../OrderContext";
import { addToCartEvent } from "../../events";


const Account = (props) => {

    const TYPE_NEW = 'new';
    const TYPE_SIGN_IN = 'sign-in';
    const [activeTab, setActiveTab] = useState(TYPE_NEW);
    const [isLoading, setIsLoading] = useState(false);

    const { orderType, order } = useContext(OrderContext);

    useEffect(() => {
        if (props.isLoggedIn) {
            setActiveTab(TYPE_SIGN_IN);
        }
    }, [props.isLoggedIn]);

    useEffect(() => {
        triggerAddToCartEvent()
    }, [order.code])

    const triggerAddToCartEvent = useCallback(() => {
        if (order && orderType) {
            addToCartEvent(order, orderType)
        }
    }, [order.code])

    const handleChangeTab = type => {
        setActiveTab(type);
    };

    return (
        <div className={styles.container}>
            <LoadingOverlay isLoading={isLoading}>
                <Row className={styles.tabs}>
                    <Col className={activeTab === TYPE_NEW ? styles.active : {}} onClick={() => handleChangeTab(TYPE_NEW)}>
                        <span className="bold font-medium">New account</span>
                    </Col>
                    <Col className={activeTab === TYPE_SIGN_IN ? styles.active : {}} onClick={() => handleChangeTab(TYPE_SIGN_IN)}>
                        <div className="text-center">
                            <span className="bold font-medium">Sign in</span>
                            <br />
                            <span className="font-tiny">(Existing customer)</span>
                        </div>
                    </Col>
                </Row>
                <Row className={styles.content}>
                    <Col>
                        {activeTab === TYPE_NEW ?
                            <NewAccount setIsLoading={setIsLoading} homeSecurePlan={props.homeSecurePlan} />
                        :
                            <Login isLoggedIn={props.isLoggedIn} setIsLoading={setIsLoading} homeSecurePlan={props.homeSecurePlan} />
                        }
                    </Col>
                </Row>
            </LoadingOverlay>
        </div>
    );

};

Account.propTypes = {
    isLoggedIn: PropTypes.bool,
    homeSecurePlan: PropTypes.oneOf([HS_SUBSCRIBE, HS_TRIAL])
};

export default Account;