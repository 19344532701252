import React, {useCallback, useContext, useEffect, useMemo} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Success.module.scss";
import OrderContext from "../../OrderContext";
import SuccessCopy from "../../components/SuccessCopy/SuccessCopy";
import {mapRouterData, TYPE_BROADBAND, NTD_LABEL} from "../../constants";
import {stripTags} from "../../globals";
import {orderCompleteSuccessEvent, orderSuccessPurchaseEvent} from "../../events"

const Success = (props) => {
    const { order, orderType } = useContext(OrderContext);

    const smbVoip = () => {
        return (
            <div>$0/mth <span className='regular font-smaller'>(For the first 6 months then $10/mth)</span></div>
        );
    }

    const mobilePromo = (planId, currentData) => {
        const promoPlanList = [417, 438, 436, 420, 438, 436]; // Promo list - hardcoded because fun
        if (!promoPlanList.includes(planId)) {
            return currentData;
        }
    }

    const checkForDevicesOrderOrNTD = useMemo(() => {
        return order.upfrontModem || order.eeroModem || order.extenderModem > 0 || order.orderNtd
    }, [order])

    useEffect(() => {
        orderCompleteEvent()
    }, [order])

    const orderCompleteEvent = useCallback(() => {
        orderCompleteSuccessEvent(order)
        orderSuccessPurchaseEvent(orderType, order)
    }, [order])

    const OrderBroadband = () => {
        return (
            <tbody>
                {order.isFlip ?
                    <React.Fragment>
                        <tr>
                            <td className="bold text-nowrap">
                                <span className="d-none d-lg-inline">Your </span>Flip to Fibre™ plan:
                            </td>
                            <td dangerouslySetInnerHTML={{__html: order.planName}} />
                        </tr>
                    </React.Fragment>
                :
                    <tr>
                        <td className="bold">Plan name:</td>
                        <td dangerouslySetInnerHTML={{__html: order.planName}} />
                    </tr>
                }
                <tr>
                    <td className="bold">Speed Tier:</td>
                    <td dangerouslySetInnerHTML={{__html: order.speedTier}} />
                </tr>
                {order.bundle ?
                    <tr>
                        <td className="bold">Internet total:</td>
                        <td>
                            <del>${order.broadbandPlanCost}</del> ${order.internetCost}/mth
                            {order.broadbandPlan && order.internetTotal.length > 0 ?
                                <p className="m-0 font-tiny">
                                    {order.internetTotal.map((descString, key) => {
                                        return (
                                            <span key={key}>{descString}<br /></span>
                                        );
                                    })}
                                </p>
                                : ''}
                        </td>
                    </tr>
                :
                    <tr>
                        <td className="bold">Monthly data:</td>
                        <td>
                            {order.monthlyData} | ${order.broadbandPlanCost}/mth
                            {order.broadbandPlan && order.broadbandPlan.length > 0 ?
                                <p className="m-0 font-tiny">
                                    {order.broadbandPlan.map((descString, key) => {
                                        return (
                                            <span key={key}>{descString}<br /></span>
                                        );
                                    })}
                                </p>
                                : ''}
                        </td>
                    </tr>
                }
                {order.bundle && order.bundleTotal > 0 ?
                    <tr>
                        <td className="bold">Bundle discount:</td>
                        <td>${order.bundleTotal}/mth <span className="font-smaller">applied above</span></td>
                    </tr>
                : ''}
                {order.promoCredit !== 0 ?
                    <tr>
                        <td className="bold">Promotional credit:</td>
                        <td>${order.promoCredit}</td>
                    </tr>
                : ''}
                <tr>
                    <td className="bold">Contract length:</td>
                    <td>{order.contract}</td>
                </tr>
                {order.upfrontModem ?
                    <tr>
                        <td className="bold">Modem & delivery:</td>
                        <td>{order.zteCost > 0 ? `$${order.zteCost}` : ' Free'} <span className="regular font-smaller">(valued at ${order.modemDefaultCost ?? '200'})</span>
                        <span className="regular font-smaller">{order.zteShipping > 0 ? ` + $${order.zteShipping} shipping` : ' (Free shipping included)'}</span></td>
                    </tr>
                : ''}
                {order.extenderModem > 0 ?
                    <tr>
                        <td className="bold">{order.extenderQuantity > 1 ? `${order.extenderQuantity} x Modem extenders` : 'Modem extender'}:
                        </td>
                        <td>{order.extenderCost > 0 ? `$${order.extenderCost}` : 'Free'} <span className="regular font-smaller">{order.extenderShipping > 0 ? ` (includes $${order.extenderShipping} shipping)` : ' (Free shipping included)'}</span></td>
                    </tr>
                : ''}
                {order.router && mapRouterData[order.router] ?
                    <tr>
                        <td className="bold">Router:</td>
                        <td>${mapRouterData[order.router].price} <span className="regular font-smaller">({mapRouterData[order.router].title})</span></td>
                    </tr>
                    : ''}
                {order.unlimitedPhone ?
                    <tr>
                        <td className="bold">{order.isSmb ? 'Unlimited business calls' : 'VoIP Unlimited call pack'}:</td>
                        <td>{order.isSmb ? smbVoip(): '$10/mth'}</td>
                    </tr>
                : ''}
                {order.paygVoip ?
                    <tr>
                        <td className="bold">VoIP Pay-as-you-go:</td>
                        <td>$0/mth</td>
                    </tr>
                : ''}
                {order.isBusiness ?
                    <tr>
                        <td className="bold">Contention type: </td>
                        <td>{order.isContended ? "Contended" : "Committed"}</td>
                    </tr>
                    : ''}
                {order.isBusiness ?
                    <tr>
                        <td className="bold">Activation fee: </td>
                        <td>${order.activationPrice}</td>
                    </tr>
                : ''}
                {order.newDevelopment ?
                    <tr>
                        <td className="bold">New development charge:</td>
                        <td>$300</td>
                    </tr>
                : ''}
                <tr>
                    <td className="bold">Total upfront costs:</td>
                    <td>${order.upfrontCost}</td>
                </tr>
                <tr>
                    <td className="bold">Total min. charge:</td>
                    <td>${order.minCost}</td>
                </tr>
                {order.orderNtd ? <tr>
                    <td className="bold">{NTD_LABEL}</td>
                    <td>Included</td>
                    </tr>: ''}
                {order.mobileBundle ? <MobileAddOn /> : ''}
            </tbody>
        );
    };

    const OrderMobile = () => {
        return (
            <tbody>
                <tr>
                    <td className="bold">Plan name:</td>
                    <td dangerouslySetInnerHTML={{__html: order.planName}} />
                </tr>
                <tr>
                    <td className="bold">Monthly data:</td>
                    <td>
                        {mobilePromo(order.planId, order.includedData)}
                    </td>
                </tr>
                <tr>
                    <td className="bold">Contract length:</td>
                    <td>{order.contract}</td>
                </tr>
                <tr>
                    <td className="bold">Total min. charge:</td>
                    <td>${order.minCost}</td>
                </tr>
                {order.simType !== "" ?
                    <tr className="bold">
                        <td className="bold">Sim Type: </td>
                        <td>{order.simType}</td>
                    </tr>
                    : ''}
                {order.features.length > 0 ?
                    <tr>
                        <td colSpan="2">
                            <p className="m-0 mb-1 bold">Inclusions:</p>
                            <ul>
                                {order.features.map((item, key) => {
                                    return (
                                        <li key={key} className="font-smaller mb-1" dangerouslySetInnerHTML={{__html: stripTags(item)}} />
                                    );
                                })}
                            </ul>
                        </td>
                    </tr>
                : ''}
                <tr>
                    <td className="bold">Monthly total:</td>
                    <td>${order.planPrice}/mth</td>
                </tr>
            </tbody>
        );
    };

    const MobileAddOn = () => {
        return (
            <>
                <tr>
                    <td className="bold" colSpan={2}><div className={styles.separator} /></td>
                </tr>
                <tr>
                    <td className="bold">Plan name:</td>
                    <td dangerouslySetInnerHTML={{__html: order.mobileBundleData.planName}} />
                </tr>
                <tr>
                    <td className="bold">Monthly data:</td>
                    <td>
                        {mobilePromo(order.mobileBundleData.planId, order.mobileBundleData.includedData)}
                    </td>
                </tr>
                <tr>
                    <td className="bold">Monthly cost:</td>
                    <td>{order.mobileBundleData.price}/mth</td>
                </tr>
                {order.mobileBundleData.features.length > 0 ?
                    <tr>
                        <td colSpan="2">
                            <p className="m-0 mb-1 bold">Inclusions:</p>
                            <ul>
                                {order.features.map((item, key) => {
                                    return (
                                        <li key={key} className="font-smaller mb-1" dangerouslySetInnerHTML={{__html: stripTags(item)}} />
                                    );
                                })}
                            </ul>
                        </td>
                    </tr>
                : ''}
                {order.simType !== "" ?
                    <tr className="bold">
                        <td className="bold">Sim Type: </td>
                        <td>{order.simType}</td>
                    </tr>
                : ''}
                <tr>
                    <td className="bold" colSpan={2}><div className={styles.separator} /></td>
                </tr>
            </>
        );
    };

    const monthlyTotal = () => {
        return order.bundle ? parseFloat(order.monthlyTotal) - parseFloat(order.bundleTotal) : order.monthlyTotal
    }

    const getMonthlyTotalAmount = () => {
        return orderType === TYPE_BROADBAND ? `$${monthlyTotal()}/mth`: `$${order.planPrice}/mth`;
    }

    const monthlyTotalUI = () => {
        return (
            <table width="100%" cellPadding="2" cellSpacing="0" className={styles.table}>
                <tbody>
                    <tr>
                        <td className="bold">Monthly total:</td>
                        <td>
                            <span className="bold">{getMonthlyTotalAmount()}</span>
                            <p className={`mt-0 font-smaller ${order.priceArray?.length > 0 ? '' : 'd-none'}`}>
                                {order.bundle && order.internetTotal ?
                                    order.internetTotal.length > 0  && order.internetTotal.map((priceString, key) => {
                                        return (
                                            <span key={key}>{priceString}<br/></span>
                                        );
                                    })
                                 :order.priceArray?.length > 0 && order.priceArray.map((priceString, key) => {
                                    return (
                                        <span key={key}>{priceString}<br/></span>
                                    );
                                })}
                            </p>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const BroadbandTerms = (
        <p className="font-smaller">This order receipt is confirmation that you have read and agree to the following documents about your Exetel service: <a href="https://files.exetel.com.au/terms/ExetelAcceptableUsePolicy.pdf" target="_blank" rel="noreferrer noopener">Acceptable Use Policy</a>, <a href="https://files.exetel.com.au/terms/Exetel-Residential-Fibre-Broadband-Terms.pdf" target="_blank" rel="noreferrer noopener">Terms & Conditions</a>, <a href="https://files.exetel.com.au/terms/CustomerServiceWaiver.pdf" target="_blank" rel="noreferrer noopener">Customer Service Guarantee Waiver</a> and <a href="https://files.exetel.com.au/terms/ExetelPrivacyPolicy.pdf" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.</p>
    );

    const MobileTerms = (
        <p className="font-smaller">This order receipt is confirmation that you have read and agree to the following documents about your Exetel service: <a href="https://files.exetel.com.au/terms/ExetelAcceptableMobileUsePolicy.pdf" target="_blank" rel="noreferrer noopener">Acceptable Use Policy</a>, <a href="https://files.exetel.com.au/terms/EXE0049-R-Mobile_FA.pdf" target="_blank" rel="noreferrer noopener">Terms & Conditions</a> and <a href="https://files.exetel.com.au/terms/ExetelPrivacyPolicy.pdf" target="_blank" rel="noreferrer noopener">Privacy Policy</a>.</p>
    );

    return (
        <div>
            <Row className={styles.content}>
                <Col>
                    <div className="mb-5 d-lg-none">
                        <h1 className="m-0 mb-4">Your order is confirmed.</h1>
                        <SuccessCopy />
                    </div>
                    <div className="mb-5">
                        <h1 className="m-0 mb-2">Your details</h1>
                        <div className={styles.separator} />
                        <table width="100%" cellPadding="2" cellSpacing="0" className={styles.table}>
                            <tbody>
                                <tr>
                                    <td className="bold">Name:</td>
                                    <td>{order.firstName} {order.lastName}</td>
                                </tr>
                                <tr>
                                    <td className="bold">Email:</td>
                                    <td>{order.email}</td>
                                </tr>
                                <tr>
                                    <td className="bold">Customer ID:</td>
                                    <td>{order.customerId}</td>
                                </tr>
                                <tr>
                                    <td className="bold">Service address:</td>
                                    <td>{order.serviceAddress}</td>
                                </tr>
                                {checkForDevicesOrderOrNTD ? <tr>
                                    <td className="bold">Delivery address:</td>
                                    <td>{order.deliveryAddress}</td>
                                </tr>: ''}
                            </tbody>
                        </table>
                    </div>
                    <div className="mb-5">
                        <h1 className="m-0 mb-2">Your order</h1>
                        <div className={styles.separator} />
                        <table width="100%" cellPadding="2" cellSpacing="0" className={styles.table}>
                            {orderType === TYPE_BROADBAND ? <OrderBroadband /> : <OrderMobile />}
                        </table>
                        {order.promotionDescription ? <div class="alert alert-success mt-3" role="alert"><span className="bold">{order.promotionDescription}</span></div>: ""}
                        {!order.bundle && <div className={styles.separator} />}
                        {monthlyTotalUI()}
                    </div>
                    {orderType === TYPE_BROADBAND ? BroadbandTerms : MobileTerms}
                </Col>
            </Row>
        </div>
    );

};

Success.propTypes = {};

export default Success;