import React from "react";
import PropTypes from "prop-types";
import styles from "./SelectField.module.scss";
import InputLabel from "../InputLabel/InputLabel";
import Select from 'react-select';

const SelectFieldAutocomplete = (props) => {
    const customStyles = {
        control: (provided, state) => ({
           ...provided,
           height: 48,
           border: state.isFocused ? "1px solid #4200B3" : props.hasError ? "2px solid #e60202": "1px solid #535353",
           width: '100%'
         }),
         option: (base, { data, isDisabled, isFocused, isSelected }) => ({
            ...base,
            backgroundColor: isFocused ? "#F5F5F5" : "",
            color: "#171717"
         })
       };
    return (
        <div className={`${props.hasError ? styles.error : ''} mb-3`}>
            <div className={styles.inner}>
                {props.label ? <InputLabel label={props.label} subLabel={props.subLabel} hasError={props.hasError} /> : ''}
                <Select defaultValue={props.defaultValue} 
                        options={props.items}
                        styles={customStyles}
                        placeholder={props.placeholder}
                        onChange={props.onChange}
                        autosize={false}
                        /> 
            </div>
        </div>
    );

};

SelectFieldAutocomplete.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    subLabel: PropTypes.string,
    placeholder: PropTypes.string,
    hasError: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
        title: PropTypes.string
    })).isRequired
};

export default SelectFieldAutocomplete;