import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Page.module.scss";

const Page = (props) => {
    return (
        <div className={styles.container}>
            {props.side ? (
                <Row>
                    <Col xs={12} lg={7} className={styles.leftContainer}>
                        {props.content}
                    </Col>
                    <Col lg={5}>{props.side}</Col>
                </Row>
            ) : (
                <Row>
                    <Col xs={12}>{props.content}</Col>
                </Row>
            )}
        </div>
    );
};

Page.propTypes = {
    content: PropTypes.object.isRequired,
    side: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
};

export default Page;
