import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import InputField from "../InputField/InputField";
import OrderContext from "../../OrderContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import {HS_SUBSCRIBE, HS_TRIAL, TYPE_MOBILE} from "../../constants";
import Error from "../Error/Error";
import {handleError, pushGa4Event} from "../../globals";
import { sha256 } from 'js-sha256'
import { useSelector } from "react-redux";
import SelectField from "../SelectField/SelectField";
import {newAccountEvent, beginCheckOutSignInOrNewAccountEvent} from "../../events"

const titles = [
    {title: 'Mr', value: 'Mr'},
    {title: 'Ms', value: 'Ms'},
    {title: 'Mrs', value: 'Mrs'},
    {title: 'Miss', value: 'Miss'},
    {title: 'Mx', value: 'Mx'},
    {title: 'Dr', value: 'Dr'},
    {title: 'Prof', value: 'Prof'},
    {title: 'Rev', value: 'Rev'},
];

const NewAccount = (props) => {

    const { order, orderType, updateOrder } = useContext(OrderContext);
    const { register, handleSubmit, control, reset, formState: { errors }} = useForm();
    const history = useHistory();
    const [validationErrors, setValidationErrors] = useState(null);
    const upsell = useSelector(state => state.upsell.value);

    useEffect(() => {
        // Form needs to be reset after an API call in order to update the values
        reset(order);
    }, [order]);

    const handleSubmitEvent = (data) => {
        data.completed = false;
        data.validateCustomer = true;
        data.homeSecurePlan = props.homeSecurePlan;
        data.idCheck = true;
        data.identifierType = 'nonce';
        data.identifier = 'fake-valid-visa-nonce';
        data.page = 'New account';

        // Update the mobile upsell into the main order object on submit
        data.mobileBundle = upsell.mobileBundle;
        data.mobileBundleData = upsell.mobileBundleData;

        props.setIsLoading(true);
        setValidationErrors(null);
        updateOrder(data).then(() => {
            newAccountEvent(data)
            beginCheckOutSignInOrNewAccountEvent(orderType, order)
            pushJourneyEvent(data);
            history.push('/connection');
        }).catch(error => {
            if (error.response.status === 422 || error.response.status === 403 || error.response.status === 401) {
                // Show validation error
                if (error.response.data && error.response.data) {
                    setValidationErrors(error.response.data.errors);
                } else {
                    //Some other error occurred, log and show error screen
                    setValidationErrors("Oops! It appears something went wrong, please try again.");
                }
            } else {
                //Some other error occurred, log and show error screen
                setValidationErrors(
                    "Oops! It appears something went wrong, please try again."
                );
                handleError(
                    "Error submitting new account details in handleSubmitEvent.",
                    error.response
                );
            }
        })
        .finally(() => {
            props.setIsLoading(false);
        });
    };

    const pushJourneyEvent = data => {
        pushGa4Event('journeyStep', {
            journeyName: orderType === TYPE_MOBILE ? 'Mobile Plans' : 'NBN Plans',
            stepName: 'New Account',
            stepNo: 6,
            hashedFirstName: sha256(data.firstName),
            hashedLastName: sha256(data.lastName),
            hashedEmail: sha256(data.email),
            hashedNumber: sha256(data.contact),
            secureSubscription: order.homeSecurePlan === HS_SUBSCRIBE ? 'True' : 'False'
        });
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitEvent)}>
            <Row className="mb-4">
                <Col>
                    <h2 className="m-0 mb-3">Enter your contact details:</h2>
                    <SelectField
                        label="Title"
                        name="salutation"
                        hasError={!!errors.salutation}
                        defaultValue={order.salutation}
                        register={register}
                        required={true}
                        items={titles}
                        placeholder="Select title"
                    />
                    <InputField
                        label="First name"
                        name="firstName"
                        hasError={!!errors.firstName}
                        defaultValue={order.firstName}
                        register={register}
                        required={true}
                    />
                    <InputField
                        label="Last name"
                        name="lastName"
                        hasError={!!errors.lastName}
                        defaultValue={order.lastName}
                        register={register}
                        required={true}
                    />
                    <InputField
                        label="Email address"
                        name="email"
                        hasError={!!errors.email}
                        defaultValue={order.email}
                        register={register}
                        required={true}
                        type="email"
                    />
                    <InputField
                        label="Contact number"
                        subLabel="(Mobile or Home Phone)"
                        name="contact"
                        hasError={!!errors.contact}
                        defaultValue={order.contact}
                        register={register}
                        required={true}
                        maxLength={10}
                    />
                    <InputField
                        label="Date of birth"
                        subLabel="DD/MM/YYYY"
                        name="dob"
                        hasError={!!errors.dob}
                        defaultValue={order.dob}
                        register={register}
                        required={true}
                        placeholder="DD/MM/YYYY"
                        type="dob"
                        control={control}
                    />
                    {order.isSmb || order.isBusiness ? (
                        <>
                            <InputField
                                label="Company number"
                                subLabel="ABN or ACN"
                                name="companyNumber"
                                hasError={!!errors.companyNumber}
                                defaultValue={order.companyNumber}
                                register={register}
                                required={true}
                                type="abn"
                                control={control}
                            />
                            <InputField
                                label="Registered company name"
                                name="companyName"
                                hasError={!!errors.companyName}
                                defaultValue={order.companyName}
                                register={register}
                                required={true}
                            />
                        </>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
            {validationErrors ? (
                <Row className="mb-4">
                    <Col>
                        <Error errors={validationErrors} />
                    </Col>
                </Row>
            ) : ''}
            <Button handleClick={handleSubmit(handleSubmitEvent)}>
                Next step
            </Button>
        </form>
    );
};

NewAccount.propTypes = {
    setIsLoading: PropTypes.func.isRequired,
    homeSecurePlan: PropTypes.oneOf([HS_SUBSCRIBE, HS_TRIAL]).isRequired,
};

export default NewAccount;
