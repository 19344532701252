import React from "react";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";

const Button = (props) => {
    const compileStyle = () => {
        let style = { backgroundColor: props.color ? props.color : "" };
        if (props.style) {
            style = { ...style, ...props.style };
        }

        return style;
    };

    return (
        <span
            className={`${props.noStyling ? styles.none : styles.button} ${
                props.className
            }`}
            onClick={props.handleClick}
            style={compileStyle()}
        >
            {props.children}
        </span>
    );
};

Button.propTypes = {
    handleClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    style: PropTypes.object,
    noStyling: PropTypes.bool,
    className: PropTypes.string,
};

Button.defaultProps = {
    className: "",
};

export default Button;
