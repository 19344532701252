const DataStore = {

    setItem(key, data) {
        if (this.isArrayOrObject(data)) {
            data = JSON.stringify(data);
        }

        sessionStorage.setItem(key, data);
    },

    getItem(key, defaultValue = null) {
        let data = sessionStorage.getItem(key);

        if (this.isJsonString(data)) {
            data = JSON.parse(data);
        }

        return (data || data === 0) ? data : defaultValue;
    },

    removeItem(key) {
        sessionStorage.removeItem(key)
    },

    clearAll() {
        sessionStorage.clear();
    },

    isArrayOrObject(data) {
        return Array.isArray(data) || (typeof data === 'object' && data !== null);
    },

    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

};

export default DataStore;