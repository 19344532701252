import React, { useContext } from "react";
import OrderContext from "../../OrderContext";

const SuccessCopy = (props) => {
    const { order } = useContext(OrderContext);

    return (
        <React.Fragment>
            <p>Thank you for your order.</p>
            {order.isBusiness ?
                <p>
                    An email is on the way and someone from Customer Care will be contacting you shortly to discuss nest steps into getting you connected smoothly and as quickly as possible. We will also email you a copy of this order page, with all your details.
                </p> :
                <p>An email is on the way, which will explain all the next steps into getting you connected smoothly and as quickly as possible. We will also email you a copy of this order page, with all your details.</p>
            }            
            <p>If you have any further questions or queries please give us a call on 13 39 38 and use your new customer ID as reference. </p>
        </React.Fragment>
    );

};

export default SuccessCopy;