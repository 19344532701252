import React from "react";
import styles from "./NotFound.module.scss";
import DataStore from "../../DataStore";
import Button from "../../components/Button/Button";
import notFound from "../../images/404-image.png";
import { EXETEL_URL } from "../../constants";

const NotFound = (props) => {

    const handleReturnHome = () => {
        window.location.assign('/');
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Oops! Your order has expired.</h1>
            {!DataStore.getItem('code') ?
                <p className="m-0 mb-5 bold"><a href={`${EXETEL_URL}/broadband/nbn`}>Click here</a> to return to the Exetel website.</p>
            : ''}
            <div className="d-inline-flex" style={{minWidth: '150px'}}>
                {DataStore.getItem('code') ?
                    <Button handleClick={handleReturnHome}>Return home</Button>
                : ''}
            </div>

            {process.env.NODE_ENV !== 'production' ?
                <div className="my-5">
                    <p><a className={styles.test} href="/?type=broadband&technology=nbn&sq_address=U2FsdGVkX195LJD%2FT2Tnzwuw%2FccW0tV7f0L7DAS4XM%2FW1rmYoPHSibd%2Ft0qNYNLmWnMgQ2RZLFLIOnSPUvOn2cnJsxeJuGPzIgod5lN6mps%3D&service_class=11&plan_id=9212&contract_term=0&unlimited_phone=1&payg_phone=0&modem=1&extender=1&hs=trial&new_dev=0">TEST URL BROADBAND - NBN HFC</a></p>
                    <p><a className={styles.test} href="/?type=broadband&technology=nbn&sq_address=U2FsdGVkX195LJD%2FT2Tnzwuw%2FccW0tV7f0L7DAS4XM%2FW1rmYoPHSibd%2Ft0qNYNLmWnMgQ2RZLFLIOnSPUvOn2cnJsxeJuGPzIgod5lN6mps%3D&service_class=4&plan_id=9212&contract_term=0&unlimited_phone=1&payg_phone=0&modem=0&extender=0&hs=trial&new_dev=0">TEST URL BROADBAND - NON HFC/FTTP</a></p>
                    <p><a className={styles.test} href="/?type=broadband&technology=nbn&sq_address=U2FsdGVkX195LJD%2FT2Tnzwuw%2FccW0tV7f0L7DAS4XM%2FW1rmYoPHSibd%2Ft0qNYNLmWnMgQ2RZLFLIOnSPUvOn2cnJsxeJuGPzIgod5lN6mps%3D&service_class=24&plan_id=9212&contract_term=0&unlimited_phone=1&payg_phone=0&modem=1&extender=1&hs=trial&new_dev=1">TEST URL BROADBAND - WITH NEW DEV FEE</a></p>
                    <p><a className={styles.test} href="/?type=broadband&technology=fibre&sq_address=U2FsdGVkX195LJD%2FT2Tnzwuw%2FccW0tV7f0L7DAS4XM%2FW1rmYoPHSibd%2Ft0qNYNLmWnMgQ2RZLFLIOnSPUvOn2cnJsxeJuGPzIgod5lN6mps%3D&service_class=1&plan_id=9018&contract_term=0&unlimited_phone=1&payg_phone=0&modem=1&extender=0&hs=trial&new_dev=0">TEST URL BROADBAND - FIBRE OPTICOMM</a></p>
                    <p><a className={styles.test} href="/?type=broadband&technology=fibre&sq_address=U2FsdGVkX1%2BBwNBj%2BZQxMmgJhWWw221zQ0FJd2tCPSVPNhvgYqxG9eKQSbHmAKNNaxyx6kjWauBIRWNNnAtajA%3D%3D&service_class=0&plan_id=9212&contract_term=1&unlimited_phone=0&payg_phone=0&modem=0&new_dev=0&hs=trial&extender=0&fdclid=&flip=1&interim_plan=100">TEST URL BROADBAND - FLIP TO FIBRE</a></p>
                    <p><a className={styles.test} href="/?type=broadband&technology=nbn&sq_address=U2FsdGVkX195LJD%2FT2Tnzwuw%2FccW0tV7f0L7DAS4XM%2FW1rmYoPHSibd%2Ft0qNYNLmWnMgQ2RZLFLIOnSPUvOn2cnJsxeJuGPzIgod5lN6mps%3D&service_class=24&plan_id=9005&contract_term=12&unlimited_phone=1&payg_phone=0&modem=1&extender=1&hs=trial&new_dev=0&smb=1">TEST URL BROADBAND - SMB NBN FIXED 12 MONTHS</a></p>
                    <p><a className={styles.test} href="/?type=broadband&technology=nbn&plan_id=9398&contract_term=12&router=cisco_c1111_4p&modem=0&payg_phone=0&unlimited_phone=0&extender=0&hs=trial&new_dev=0&sq_address=U2FsdGVkX18rkThecHojsEhF4iAG0kDtqCT7R7ppYlypUszsJjzzAyK6UTkCt6DQljV7bhyQN9k4x1yk7vERGA%3D%3D&service_class=3&business=1">TEST URL NBN ENTERPRISE ETHERNET</a></p>
                    <p><a className={styles.test} href="/?type=mobile&plan_id=416&contract_term=0">TEST URL MOBILE</a></p>
                </div>
            : ''}

            <div className="mt-5">
                <img src={notFound} alt="Not found" className={styles.image} />
            </div>
        </div>
    );

};

NotFound.propTypes = {};

export default NotFound;