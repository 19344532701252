import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Button from "../Button/Button";
import Row from "react-bootstrap/Row";
import DataSource from "../../DataSource";
import InputField from "../InputField/InputField";
import {useForm} from "react-hook-form";
import Error from "../Error/Error";
import Success from "../Success/Success";
import DataStore from "../../DataStore";

const VerifyMobile = (props) => {

    const { register, getValues, setValue, formState: { errors }} = useForm();
    const [isSent, setIsSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (props.resetMobileVerification) {
            setIsVerified(false)
            setValue('code' , '')
        }
    }, [props.resetMobileVerification])

    const requestToken = () => {
        if (props.mobile) {
            setError('');
            setIsSent(false);
            DataSource.requestMobileToken(props.mobile.replaceAll(' ', ''))
                .then(response => {
                    setIsSent(true);
                })
                .catch(error => {
                    if (error.response.status === 422 || error.response.status === 401 || error.response.status === 403) {
                        setError(error.response.data.errors);
                    } else {
                        setError('Oops, something went wrong, please try again.');
                    }
                });
        }
    };

    const verifyToken = () => {
        let token = getValues('code');
        setError('');
        setIsVerified(false);
        DataSource.verifyMobileToken(props.mobile.replaceAll(' ', ''), token)
            .then(response => {
                DataStore.setItem('verifiedMobileNumber', props.mobile)
                setIsVerified(true);
                props.handleVerified();
            })
            .catch(error => {
                if (error.response.status === 422 || error.response.status === 403 || error.response.status === 401) {
                    setError(error.response.data.errors);
                } else {
                    setError('Oops, something went wrong, please try again.');
                }
            });
    };

    return (
        <React.Fragment>
            <Row className="mb-3 align-items-center">
                <Col lg={8}>
                    <p className="m-0 mb-2 mb-lg-0 font-smaller text-center text-lg-start">To verify this transfer, we need to send a confirmation code via SMS to the mobile number above.</p>
                </Col>
                <Col lg={4} className="d-flex justify-content-center justify-content-lg-end">
                    <Button handleClick={requestToken} style={{width: '60%', height: '45px'}}>Send code</Button>
                </Col>
            </Row>
            {isSent ?
                <Row className="mb-3 align-items-center">
                    <Col lg={6}>
                        <p className="font-smaller m-0 mb-1 bold">Code sent</p>
                        <p className="font-smaller m-0">Please enter your confirmation code:</p>
                    </Col>
                    <Col lg={6} className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <InputField
                                name="code"
                                hasError={!!errors.code}
                                register={register}
                                required={true}
                                noMargin={true}
                                placeholder="Confirmation code"
                            />
                        </div>
                        <div className="ms-2">
                            <Button handleClick={verifyToken} style={{width: '85px', height: '45px'}}>Verify</Button>
                        </div>
                    </Col>
                </Row>
            : ''}
            {error ?
                <Col lg={12} className="mb-3">
                    <Error errors={error} />
                </Col>
            : ''}
            {isVerified ?
                <Row className="mb-3">
                    <Col>
                        <Success>Your mobile number has been verified.</Success>
                    </Col>
                </Row>
            : ''}
        </React.Fragment>
    );

};

VerifyMobile.propTypes = {
    mobile: PropTypes.string,
    handleVerified: PropTypes.func.isRequired,
};

export default VerifyMobile;