import React from "react";
import PropTypes from "prop-types";
import styles from "./ReadOnlyInputField.module.scss";
import InputLabel from "../InputLabel/InputLabel";

const ReadOnlyInputField = (props) => {

    return (
        <div className="mb-3">
            <div className={styles.inner}>
                {props.label ? <InputLabel label={props.label} subLabel={props.subLabel} /> : ''}
                <div className={styles.inputContainer}>
                    <input
                        className={styles.input}
                        readOnly={true}
                        value={props.value}
                    />
                </div>
            </div>
        </div>
    );

};

ReadOnlyInputField.propTypes = {
    label: PropTypes.string,
    subLabel: PropTypes.string,
    value: PropTypes.string
};

export default ReadOnlyInputField;