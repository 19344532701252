import DataSource from "../DataSource";
import DataStore from "../DataStore";
export const GetToken =  {
     async getOauthToken (){
        const response = await DataSource.requestOAUTHToken()
        if (response.status === 200 && response.data) {
            return response.data;
        }
        return '';
    },

    setWithExpiry(key, item) {
        const data = {
            value: item.access_token,
            expiry: Math.floor(Date.now() / 1000) + item.expires_in
        }
        DataStore.setItem(key, data)
    },

    getWithExpiry(key) {
        const item = DataStore.getItem(key)
        const now = Math.floor(Date.now() / 1000)
        if (!item) {
            return null;
        }
        if(now > item.expiry) {
            DataStore.removeItem(key)
            return null
        }
        return item
    },

    async getClientToken(){
        const result = await DataSource.requestBraintreeClientToken()
        if (result.status === 'success') {
            return result.token
        }

        return ''
    }
}