import React, { useState } from "react";
import { MFA_SMS, MFA_EMAIL } from "../../constants";
import Button from "../../components/Button/Button";
import PropTypes from 'prop-types'
import Error from "../../components/Error/Error";

const MfaForm = (props) => {
    const [selectedOption, setSelectedOption] = useState('')
    const [error, setError] = useState(props.error)
    const handleSelectMfa = (value) => {
        setSelectedOption(value)
        setError('')
    }

    const handleMFASubmit = () => {
        if(!selectedOption) {
            setError('Please choose an option')
        } else {
            props.handleSubmit(selectedOption)
        }
    }

    return (
        <form onSubmit={() => handleMFASubmit()} onKeyDown={() => handleMFASubmit()}>
            <div>
                <p>Please provide the one-time verification code sent to you.</p>
                <p>This is a process called Multi-Factor Authentication (MFA), which has been introduced to strengthen the security of your account.</p>
            </div>

            {props.mobileNumber && <label className='d-block mt-4'>
                <input type="radio" value={MFA_SMS} name="action" onChange={() => handleSelectMfa(MFA_SMS)} checked={selectedOption === MFA_SMS} />
                &nbsp;Send an SMS to - { props.mobileNumber }
            </label>}
            
            {props.emailAddress && <label className='d-block mt-4'>
                <input type="radio" value={MFA_EMAIL} name="action" onChange={() => handleSelectMfa(MFA_EMAIL)} checked={selectedOption === MFA_EMAIL} />
                &nbsp;Send an email to - { props.emailAddress}
            </label>}            

            {props.children}
            
            {(props.error || error) && <Error errors={error ? error: props.error}/>}

            <div className='mt-4'>
                <Button handleClick={handleMFASubmit}>Send Code</Button>
            </div>
        </form>
    )
}

MfaForm.prototype = {
    handleSubmit: PropTypes.func,
    handleKeyDown: PropTypes.func,
    mobileNumber: PropTypes.number,
    emailAddress: PropTypes.string
}

export default MfaForm