import React from "react";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import styles from "./BackNextButtons.module.scss";

const BackNextButtons = (props) => {

    return (
        <div className={`${props.className && props.className} d-flex`}>
            <div className={styles.backBtn}>
                <Button handleClick={props.handleBack} color="#F7F8F8">Back</Button>
            </div>
            <div className={styles.nextBtn}>
            <Button className={props.classNextBtn && props.classNextBtn} handleClick={props.handleSubmit}>{props.title}</Button>
            </div>
        </div>
    );

};

BackNextButtons.propTypes = {
    handleBack: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    title: PropTypes.string
};

BackNextButtons.defaultProps = {
    title: 'Next step'
};

export default BackNextButtons;