import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styles from "./Header.module.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import logo from "../../images/exetel-logo.svg";
import Progress from "../Progress/Progress";
import Summary from "../Summary/Summary";
import OrderContext from "../../OrderContext";
import { EXETEL_URL } from "../../constants";
import { agentLogout } from "../../utils/AgentUtil";

const Header = (props) => {

    const { order } = useContext(OrderContext);

    const [isAgent, setIsAgent] = useState(localStorage.getItem('isAgent'))
    const [agentPortalUsername, setAgentPortalUsername] = useState('')
    
    const onAgentLogout = () => {
        agentLogout()
        setIsAgent(false);
        window.dispatchEvent(new CustomEvent('isAgentChanged'));
    }

    useEffect(() => {
        const isAgentFlag = localStorage.getItem('isAgent') === 'true';
        const username = localStorage.getItem('agentUsername') || 'User';
        setIsAgent(isAgentFlag);
        setAgentPortalUsername(username);
    }, [isAgent]);

    return (
        <div className={styles.header}>
            <Row className="p-2 mb-2 p-lg-0 m-lg-0">
                <Col>
                    <a href={order.isSmb || order.isBusiness ? 'https://business.exetel.com.au' : EXETEL_URL} target="_blank" rel="noreferrer noopener">
                        <img src={logo} alt="Exetel" width="60px" height="60px" />
                    </a>
                </Col>
                <Col className="d-flex d-lg-none justify-content-end align-items-center">
                    {/*<img src={iconClose} alt="Close" />*/}
                </Col>
            </Row>
            {!props.isSuccess ?
                <React.Fragment>
                    <Row className="d-lg-none justify-content-center">
                        <Col>
                            <Summary />
                        </Col>
                    </Row>
                    <div className="mt-3 mt-lg-0">
                        <Progress step={props.step} />
                    </div>
                </React.Fragment>
                :
                <div className={styles.confirmed}>
                    <div>
                        Your order is confirmed
                        <div className={styles.underline} />
                    </div>
                </div>
            }
            <div className={styles.callUs}>
                {isAgent ? 
                        <p className={styles.logoutButton} onClick={onAgentLogout}>Not {agentPortalUsername}? Log Out</p>
                : <>
                        <p className="bold m-0 mb-2"><a href="tel:133938">Call us on 13 39 38</a></p>
                        <p className="font-smaller m-0">Questions? Talk to an expert.</p>
                  </>
                }
            </div>
        </div>
    );

};

Header.propTypes = {
    step: PropTypes.number.isRequired,
    isSuccess: PropTypes.bool
};

export default Header;