import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styles from "./Progress.module.scss";

const Progress = (props) => {

    const Links = () => {
        return (
            <div className={styles.links}>
                <div className={styles.link}>
                    <Link to="/" className={props.step === 1 ? styles.active : ''}>Account</Link>
                </div>
                <div className={styles.separator}>></div>
                <div className={styles.link}>
                    {props.step === 3 ?
                        <Link to="/connection" className={props.step === 2 ? styles.active : ''}>Connection</Link>
                    :
                        <p className={props.step === 2 ? styles.active : ''}>Connection</p>
                    }
                </div>
                <div className={styles.separator}>></div>
                <div className={styles.link}>
                    <p className={props.step === 3 ? styles.active : ''}>Payment</p>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <div className="d-lg-none">
                <div className="text-center mb-2 font-tiny">
                    (Order progress: <span className="bold">Step {props.step} of 3</span>)
                </div>
                <div className={styles.block}>
                    <div className={styles.progress} style={{width: props.step === 1 ? '0' : props.step === 2 ? '33.33%' : '66.66%'}} />
                </div>
                <Links />
            </div>
            <div className="d-none d-lg-flex position-relative">
                <Links />
                <div className={`${styles.activeBlock} ${props.step === 2 ? styles.activeStep2 : props.step === 3 ? styles.activeStep3 : ''}`} />
            </div>
        </div>
    );

};

Progress.propTypes = {
    step: PropTypes.number.isRequired
};

export default Progress;