import React from "react";
import InputField from "../../components/InputField/InputField";
import Button from "../../components/Button/Button";
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form';
import Error from "../../components/Error/Error";

const MfaCodeForm = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        if(data?.token.length > 2){
            props.handleSubmitMfa(data.token)
        }
    }

    const handleResendCode = () => {
        props.handleResendCode()
    }


    return (
        <form onSubmit={handleSubmit()}>
            <div>
                <p>Please provide the one-time verification code sent to you.</p>
                <p>This is a process called Multi-Factor Authentication (MFA), which has been introduced to strengthen the security of your account.</p>
            </div>

            <InputField
                placeholder='4 digit code'
                name='token'
                hasError={!!errors.token}
                register={register}
                required={true}
                type='password'
                
            />

            {props.children}

            {(props.error || errors.token) ? <Error errors={props.error ? props.error: 'The token field is required.'}/>: ''}

            <div className='mt-lg-4 d-lg-flex gap-3'>
                <Button  handleClick={handleSubmit(onSubmit)} className="mr-lg-3 mb-2 mb-lg-0">Verify</Button>
                <Button  handleClick={handleResendCode} color="#171717" style={{color: "#FFFFFF"}}>Resend Code</Button>
            </div>
        </form>
    )
}

MfaCodeForm.prototype = {
    handleSubmit: PropTypes.func,
    handleKeyDown: PropTypes.func,
    handleResendCode: PropTypes.func,
    handleInputChange: PropTypes.func,
    values: PropTypes.shape({
        token: PropTypes.string
    })
}

export default MfaCodeForm