import { createSlice } from '@reduxjs/toolkit'

export const upsellSlice = createSlice({
    name: 'upsell',
    initialState: {
        value: {}
    },
    reducers: {
        updateUpsell: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const { updateUpsell } = upsellSlice.actions

export default upsellSlice.reducer