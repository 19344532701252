import React from "react";
import PropTypes from "prop-types";
import styles from "./LoadingOverlay.module.scss";
import loader from "../../images/loader.svg";

const LoadingOverlay = (props) => {

    return (
        <div>
            {props.isLoading ?
                <div className={`${styles.overlay} ${props.isFixed ? styles.fixed : ''}`}>
                    <img src={loader} alt="loading..." />
                </div>
            : ''}
            {props.children}
        </div>
    );

};

LoadingOverlay.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isFixed: PropTypes.bool
};

export default LoadingOverlay;