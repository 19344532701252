import { pushGa4Event } from "./globals"
import { sha256 } from "js-sha256"
import {buildEcommerceItems} from "./utils/ecommerce"
import { TECH_FIBRE, TYPE_MOBILE } from "./constants"



export const addToCartEvent = (order, orderType) => {
    pushGa4Event('add_to_cart', {
        ecommerce: buildEcommerceEvent(orderType, order)
    })
}

export const newAccountEvent = order => {
 const tagData = {
    hashedFirstName: sha256(order.firstName),
    hashedLastName: sha256(order.lastName),
    hashedPhoneNumber: sha256(order.contact),
    hashedEmail: sha256(order.email),
    orderCategory: order.isSmb ? "Business": "Residential",
    orderType: getOrderType(order)
}
    pushGa4Event(order.isSmb ? "createNewAccountBusiness": "createNewAccountResi", {
        eventInfo: tagData
    })
   
}

export const signInAccountEvent = order => {
    const tagData = {
        hashedUserName: sha256(order?.contactEmail),
        orderCategory: order.isSmb ? "Business": "Residential",
        orderType: getOrderType(order)
    }

    pushGa4Event(order.isSmb ? "signInAccountBusiness": "signInAccountResi", {
        eventInfo: tagData
    })
}

export const beginCheckOutSignInOrNewAccountEvent = (orderType, order) => {
    pushGa4Event('begin_checkout', {
        ecommerce: buildEcommerceEvent(orderType, order)
    })
}

export const connectionSubmitEvent = (order) => {
    const tagData = {
        hashedFullName: sha256(order.firstName + ' ' + order.lastName),
        orderCategory: order.isSmb ? "Business": "Residential",
        orderType: getOrderType(order)
    }
    pushGa4Event(order.isSmb ? "connectionSubmitBusiness": "connectionSubmitResi" ,{
        eventInfo: tagData
    })
}

export const addShippingInfoConnectionPageEvent =  (orderType, order) => {
    pushGa4Event('add_shipping_info', {
        ecommerce: buildEcommerceEvent(orderType, order)
    })
}

export const paymentSubmitEvent = order => {
 const tagData = {
        paymentType: order.paymentMethod,
        orderCategory: order.isSmb ? "Business": "Residential",
        orderType: getOrderType(order)
    }
    pushGa4Event(order.isSmb ? "paymentSubmitBusiness": "paymentSubmitResi", {
        eventInfo: tagData
    })
}

export const paymentInfoEvent = (orderType, order) => {
    pushGa4Event('add_payment_info', {
        ecommerce: buildEcommerceEvent(orderType, order)
    })
}

export const orderCompleteSuccessEvent = order => {
    const tagData = {
        planName: order.planName,
        contractType: order.contractTerm,
        upfrontCost: order.upfrontCost,
        minCharge: order.minCost,
        monthlyTotal: order.monthlyTotal,
        actualOrderValue: parseFloat((order.broadbandPlanCost / 1.1).toFixed(2)),
        orderCategory: order.isSmb ? "Business": "Residential",
        order_id: order.code,
        orderType: getOrderType(order)
    }
    let eventName = ''
    if (order.isSmb) {
        eventName = "smbOrderComplete"
    } else if(order.type === TYPE_MOBILE) {
        eventName = "mobileOrderComplete"
    } else {
        eventName = order.technology === TECH_FIBRE ? "fibreOrderComplete" : "nbnOrderComplete"
    }

    pushGa4Event(eventName, {
        eventInfo: tagData
    })
}

export const orderSuccessPurchaseEvent = (orderType, order) => {
    pushGa4Event('purchase', {
        ecommerce: buildEcommerceEvent(orderType, order)
    })
}

const buildEcommerceEvent = (orderType, order) => {
    return {
        currency: "aud",
        transaction_id: order.code,
        coupon: order.coupon,
        value: order.minCost,
        items: buildEcommerceItems(orderType, order)
    }
}

const getOrderType = (order) => {
    if (order.isFlip) {
        return order.isSmb ? "SMB f2f": "f2f"
    }
    return order.isSmb ? `SMB ${order.technology}`: order.technology
}


export const pushBeginCheckoutEvent = (order, orderType) =>
    pushGa4Event('begin_checkout', {
        ecommerce: {
            currency: 'aud',
            value: order.minCost,
            coupon: "n/a",
            items: buildEcommerceItems(orderType, order)

        }
    });


 export   const pushJourneyEventSignIn = (data, orderType) => {
        pushGa4Event('journeyStep', {
            eventInfo: {
                journeyName: orderType === TYPE_MOBILE ? 'mobile plans' : 'nbn plans',
                stepName: 'sign-in',
                stepNo: orderType === TYPE_MOBILE ? '2' : '3',
                hashedEmail: sha256(data.contactEmail),
                hashedNumber: sha256(data.contactMobile),
            }
        });
    }; 