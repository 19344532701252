import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './layout/App/App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from "./layout/ScrollToTop/ScrollToTop";
import * as Sentry from '@sentry/browser';
import store from './store';
import { Provider } from 'react-redux'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://94f02c84ec844f6ca54ca957250d7407@sentry2.intdev.exetel.com.au/5"});
}

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <ScrollToTop />
            <Provider store={store}>
                <App />
            </Provider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
