import React from "react";
import SuccessCopy from "../SuccessCopy/SuccessCopy";
import styles from "./SuccessPanel.module.scss";

const SuccessPanel = (props) => {

    return (
        <div>
            <div className={styles.content}>
                <h1 className="m-0 mb-4">What happens next?</h1>
                <div className={styles.separator} />
                <SuccessCopy />
            </div>
        </div>
    );

};

SuccessPanel.propTypes = {};

export default SuccessPanel;