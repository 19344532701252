import React from "react";
import PropTypes from "prop-types";
import styles from "./SelectField.module.scss";
import iconError from "../../images/icon-error.svg";
import InputLabel from "../InputLabel/InputLabel";

const SelectField = (props) => {

    return (
        <div className={`${props.hasError ? styles.error : ''} mb-3`}>
            <div className={styles.inner} style={props.hasError ? {paddingRight: '30px'} : {}}>
                {props.label ? <InputLabel label={props.label} subLabel={props.subLabel} hasError={props.hasError} /> : ''}
                <div className={styles.inputContainer}>
                    <select
                        className={styles.input}
                        {...props.register(props.name, { required: props.required })}
                        defaultValue={props.defaultValue ? props.defaultValue : ''}
                    >
                        {props.placeholder ? <option value="" disabled>{props.placeholder}</option> : ''}
                        {props.items.map((item, key) => {
                            return <option key={key} value={item.value}>{item.title}</option>;
                        })}
                    </select>
                    {props.hasError ?
                        <div className={styles.errorIcon}>
                            <img src={iconError} alt="Error" />
                        </div>
                    : ''}
                </div>
            </div>
        </div>
    );

};

SelectField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    register: PropTypes.func,
    required: PropTypes.bool,
    subLabel: PropTypes.string,
    placeholder: PropTypes.string,
    hasError: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    items: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
        title: PropTypes.string
    })).isRequired
};

export default SelectField;